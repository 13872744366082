import React from 'react';

import './index.scss';

class FaqList extends React.Component {

  state={
    display: null
  }

  toggle=()=> {
    this.setState({
      display: !this.state.display
    })
  }

  render () {
    
    return (
      <div className="P-questions-list">
      <ul>
        <li onClick={this.toggle}
          className={`P-questions-item ${this.state.display ? "P-questions-item-show" : this.state.display === false ? "P-questions-item-hide" : ''}`}>
            <p className="P-title">
              <button></button>
              {this.props.title}
            </p>
            <span className={`P-description P-description-anim`}>
              {this.props.description}
            </span>
        </li>
      </ul>
    </div>
    )
  }
}


export default FaqList;