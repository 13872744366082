import Connection from '../../services/connection';

class SupportController {

  static send = async (data) => {
    return Connection.POST({
      controller: 'support',
      action: '',
      body: data,
    });
  }
}

export default SupportController;
