import React from 'react';
import MapContainer from './components/Google-map'
import BoxIcon from './../../assets/images/Box.svg'

import Loader from '../../platform/loader/loader'
import './index.scss';
import SupportController from '../../platform/api/support/support';
import Languages from '../../platform/services/languages'
import { MyContext } from '../../App';

const {
  name,
  contact_us,
  contact_us_text,
  email,
  phone_number,
  message,
  min_5_max_500,
  send,
  close,
  message_sent,
  Thank_you_for,
  errorMessages,
} = Languages.Translations;

class ContactUs extends React.Component {

  state = {
    name: '',
    email: '',
    message: '',
    messageSubjectEnumValue: 4,
    phoneNumber: '+374',
    subject: 'Other',
    showNotification: false,
    isOpen: false,
    loader: false,

    errors: {
      number: false,
      message: false,
      name: false,
      email: false
    }
  }

  submit = async () => {
    let isValid = true;
    const ERRORS = {
      number: false,
      message: false,
      name: false,
      email: false
    };

    const beeline = `[0-9]{2}`;
    const ucom = '55|95|41|44';
    const mts = '93|94|77|49|98';

    // eslint-disable-next-line no-useless-escape
    const phoneReg = new RegExp(`^[\+](374)?((?:${mts}|${beeline}|${ucom})([0-9]{6}))$`);
    const messageReg = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);

    if (!phoneReg.test(this.state.phoneNumber)) {
      isValid = false;
      ERRORS.number = true;
    }
    if (this.state.message.length < 5 || this.state.message.length > 500 || !messageReg.test(this.state.message)) {
      isValid = false;
      ERRORS.message = true;
    }
    if (this.state.name.length < 4) {
      isValid = false;
      ERRORS.name = true;
    }
    if (!this.state.email.includes("@") || this.state.email.length < 5) {
      isValid = false;
      ERRORS.email = true;
    }

    this.setState({ errors: ERRORS })

    if (isValid) {
      const reqObj = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        messageSubjectEnumValue: this.state.messageSubjectEnumValue,
        phoneNumber: this.state.phoneNumber,
        subject: this.state.subject,
      };
      this.setState({ loader: true })
      const result = await SupportController.send(reqObj);
      this.setState({ loader: false })
      if (result.success) {
        this.state.name = '';
        this.state.email = '';
        this.state.phoneNumber = '+374';
        this.state.message = '';
        this.popupOpen();
      }
    }
  }

  popupOpen = () => {
    this.setState({ isOpen: true });
  }

  popupClose = () => {
    this.setState({ isOpen: false });
  }

  changeEmail = (event) => {
    this.setState({ email: event.target.value })
  }
  changeName = (event) => {
    this.setState({ name: event.target.value })
  }
  changeSubject = (event) => {
    this.setState({ subject: 'Other' })
  }
  changeNumber = (event) => {
    if (event.target.value.length < 4) {
      event.target.value = '+374';
    }
    this.setState({ phoneNumber: event.target.value })
  }
  changeMessage = (event) => {
    this.setState({ message: event.target.value })
  }
  changemessageSubjectEnumValue = (event) => {
    this.setState({ messageSubjectEnumValue: 4 })
  }

  render() {
    const { loader } = this.state;

    return (
      <MyContext.Consumer>
        {(value) => {
          return (

            <div className="P-page">

              <div className={`${!this.state.isOpen ? "P-not-none" : "P-notification"}`}>
                <div className="P-popup">
                  <img src={BoxIcon} alt="" />
                  <h2>{value.data.contact_us_popup_title || 'contact_us_popup_title'}</h2>
                  <p>{value.data.contact_us_popup_description || 'contact_us_popup_description'}</p>
                  <button onClick={this.popupClose}>{value.data.close || 'close'}</button>
                </div>
              </div>

              <div className="P-contactus-table">

                <div className="P-contactus-left">
                  <h1>{value.data.contact_us || 'contact_us'}</h1>
                  <p>{value.data.contact_us_description || 'contact_us_description'}</p>
                  <div className="P-contactus-inputs">
                    <h6>{value.data.contact_us_name || 'contact_us_name'}</h6>
                    <input
                      type="text"
                      value={this.state.name}
                      onChange={this.changeName}
                      className={`${this.state.errors.name ? "P-red-border" : "P-grey-border"}`} />
                    <div className={`${this.state.errors.name ? "red" : "none"}`} > {value.data.contact_us_name_error_message || 'contact_us_name_error_message'} </div>
                  </div>
                  <div>
                    <h6>{value.data.contact_us_email || 'contact_us_email'}</h6>
                    <input
                      type="email"
                      value={this.state.email}
                      onChange={this.changeEmail}
                      className={`${this.state.errors.email ? "P-red-border" : "P-grey-border"}`} />
                    <div className={`${this.state.errors.email ? "red" : "none"}`} > {value.data.contact_us_email_error_message || 'contact_us_email_error_message'} </div>
                  </div>
                  <div>
                    <h6>{value.data.contact_us_phone_number || 'contact_us_phone_number'}</h6>
                    <input
                      type="text"
                      value={this.state.phoneNumber}
                      onChange={this.changeNumber}
                      className={`${this.state.errors.number ? "P-red-border" : "P-grey-border"}`}
                    />
                    <div className={`${this.state.errors.number ? "red" : "none"}`} > {value.data.contact_us_phone_number_error_message || 'contact_us_phone_number_error_message'} </div>
                  </div>
                  <div>
                    <h6>{value.data.contact_us_message || 'contact_us_message'} <span>{value.data.contact_us_min_symbols || 'contact_us_min_symbols'}</span></h6>
                    <textarea
                      className={`${this.state.errors.message ? "P-red-message-border" : "P-message-input"}`}
                      type="text"
                      value={this.state.message}
                      onChange={this.changeMessage}
                    />
                    <div className={`${this.state.errors.message ? "red" : "none"}`} > {value.data.contact_us_message_error_message || 'contact_us_message_error_message'} </div>
                  </div>
                  <button onClick={this.submit} >{loader ? <Loader /> : `${value.data.send || 'send'}`} </button>
                </div>

                <div className="P-contactus-right"><MapContainer></MapContainer></div>
              </div>

            </div>
          )
        }}
      </MyContext.Consumer>
    );
  }
}

export default ContactUs;
