import React from 'react';
import { ROUTES } from '../../platform/constants/routes';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './index.scss';
import ClickOutSide from '../../platform/click-outside/ClickOutside'
import Web from '../../components/web/web'
import Mobile from '../../components/mobile/mobile'
import UserImg from '../../assets/images/default-user.png'
import EmptyImg from '../../assets/images/odaparik.gif'
import DonationController from '../../platform/api/donations/donations'
import Languages from '../../platform/services/languages'
import Paging from '../../platform/pagination/pagination';
import { MyContext } from '../../App';

const {
  our_donations,
  view_participants,
  participants_list,
  participants,
  amount,
  date,
  have_a_question,
  have_a_question_text,
  contact_form,
  no_search,
  Search,
  close,
  our_donations_text,
  our_donations_text2,
  donations_history,
  projects,
  Donation_empty_title,
  Donation_empty_descr,
} = Languages.Translations;

class Donations extends React.Component {

  state = {
    isOpen: false,
    page: 0,
    size: 8,
    text: '',
    campaignId: null,
    donationList: [],
    participantsList: [],
    inputTimer: null,
    empty: false,
    totalPages: null,
  }


  componentDidMount = () => {
    this.getWinnProjects()
  }
  getWinnProjects = async (pageNum) => {
    const { page, size } = this.state
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await DonationController.getWinnProjects({ page: pageNum ? pageNum.selected : page, size });
    if (result.success) {
      this.setState({
        totalPages: result.data.totalPages,
        donationList: result.data.content,
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }

  getParticipantsList = async (campaignId) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const { text } = this.state
    const result = await DonationController.getParticipantsList(campaignId, text);
    if (result.success) {
      this.setState({ participantsList: result.data, empty: false })
      if (!this.state.participantsList.length) {
        this.setState({ empty: true })
      }
    } 
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }

  popupOpen = async (campaignId) => {
    await this.getParticipantsList(campaignId)
    this.setState({ isOpen: true, campaignId: campaignId });
    document.body.style.overflow = "hidden";
  }

  popupClose = () => {
    this.setState({ isOpen: false, participantsList: [] });
    document.body.style.overflow = "auto";
  }

  onSearch = async (evt) => {
    const { campaignId, text } = this.state

    await this.setState({ text: evt.target.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.getParticipantsList(campaignId, text);
    }, 300);
  }

  render() {

    const { donationList, participantsList, text, empty, totalPages } = this.state
    return (
      <MyContext.Consumer>
        {(value) => {
          return (

            <div className="G-page">
              <div className="P-bg-image G-flex G-flex-column G-justify-center" style={{ backgroundImage: `url(${value.dataImage.projects_top ? `${value.dataImage.projects_top}` : ''})` }}>
                <div className="P-left-box G-mb-2"><h1 className="">{value.data.privacy_policy || 'privacy_policy'}</h1></div>
              </div>
              
                <div className="P-donations">
                  <h4 className="G-mb-2">{value.data.privacy_policy || 'privacy_policy'}</h4>

                  <div className="P-privacy-section">

                    <p className="P-privacy-title G-mb-2 Fw-bolder">1. {value.data.privacy_title_1 || 'privacy_title_1'}</p>
                    <p>1.1 {value.data.privacy_text_1_1 || 'privacy_text_1_1'}</p>
                    <p>1.2 {value.data.privacy_text_1_2 || 'privacy_text_1_2'}</p>
                    <p>1.3 {value.data.privacy_text_1_3 || 'privacy_text_1_3'}</p>
                    <p>1.4 {value.data.privacy_text_1_4 || 'privacy_text_1_4'}</p>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">2. {value.data.privacy_title_2 || 'privacy_title_2'}</p>
                    <p>2.1 {value.data.privacy_text_2_1 || 'privacy_text_2_1'}</p>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">3. {value.data.privacy_title_3 || 'privacy_title_3'}</p>
                    <p>3.1 {value.data.privacy_text_3_1 || 'privacy_text_3_1'}</p>
                    <p>3.2 {value.data.privacy_text_3_2 || 'privacy_text_3_2'}</p>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">4. {value.data.privacy_title_4 || 'privacy_title_4'}</p>
                    <ul>
                        <li>{value.data.privacy_ul_4_1 || 'privacy_ul_4_1'}</li>
                        <li>{value.data.privacy_ul_4_2 || 'privacy_ul_4_2'}</li>
                    </ul>
                    <p>4.1 {value.data.privacy_text_4_1 || 'privacy_text_4_1'}</p>
                    <p className="G-mt-1"><span className="Fw-bolder">{value.data.privacy_span_4_1 || 'privacy_span_4_1'} - </span> {value.data.privacy_text_4_1_1 || 'privacy_text_4_1_1'}</p>
                    <p className="G-mt-1"><span className="Fw-bolder">{value.data.privacy_span_4_2 || 'privacy_span_4_2'} - </span> {value.data.privacy_text_4_1_2 || 'privacy_text_4_1_2'}</p>
                    <p className="G-mt-1"><span className="Fw-bolder">{value.data.privacy_span_4_3 || 'privacy_span_4_3'} - </span> {value.data.privacy_text_4_1_3 || 'privacy_text_4_1_3'}</p>
                    <p className="G-mt-1"><span className="Fw-bolder">{value.data.privacy_span_4_4 || 'privacy_span_4_4'} - </span> {value.data.privacy_text_4_1_4 || 'privacy_text_4_1_4'}</p>
                    <p className="G-mt-1"><span className="Fw-bolder">{value.data.privacy_span_4_5 || 'privacy_span_4_5'} - </span> {value.data.privacy_text_4_1_5 || 'privacy_text_4_1_5'}</p>
                    <p className="G-mt-1"><span className="Fw-bolder">{value.data.privacy_span_4_6 || 'privacy_span_4_6'} - </span> {value.data.privacy_text_4_1_6 || 'privacy_text_4_1_6'}</p>
                    <p className="G-mt-1">
                      <span className="Fw-bolder">{value.data.privacy_span_4_7 || 'privacy_span_4_7'} - </span>
                      <ul>
                          <li>{value.data.privacy_text_4_1_7_1 || 'privacy_text_4_1_7_1'}</li>
                          <li>{value.data.privacy_text_4_1_7_2 || 'privacy_text_4_1_7_2'}</li>
                          <li>{value.data.privacy_text_4_1_7_3 || 'privacy_text_4_1_7_3'}</li>
                      </ul>
                    </p>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">5. {value.data.privacy_title_5 || 'privacy_title_5'}</p>
                    <p>5.1 {value.data.privacy_text_5_1 || 'privacy_text_5_1'}</p>
                    <ul>
                        <li>{value.data.privacy_ul_5_1 || 'privacy_ul_5_1'}</li>
                        <li>{value.data.privacy_ul_5_2 || 'privacy_ul_5_2'}</li>
                        <li>{value.data.privacy_ul_5_3 || 'privacy_ul_5_3'}</li>
                        <li>{value.data.privacy_ul_5_4 || 'privacy_ul_5_4'}</li>
                        <li>{value.data.privacy_ul_5_5 || 'privacy_ul_5_5'}</li>
                    </ul>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">6. {value.data.privacy_title_6 || 'privacy_title_6'}</p>
                    <p>6.1 {value.data.privacy_text_6_1 || 'privacy_text_6_1'}</p>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">7. {value.data.privacy_title_7 || 'privacy_title_7'}</p>
                    <p>7.1 {value.data.privacy_text_7_1 || 'privacy_text_7_1'}</p>

                    <p className="P-privacy-title G-mt-2 G-mb-2 Fw-bolder">8. {value.data.privacy_title_8 || 'privacy_title_8'}</p>
                    <p>8.1 {value.data.privacy_text_8_1 || 'privacy_text_8_1'}</p>
                    <p>8.2 {value.data.privacy_text_8_2 || 'privacy_text_8_2'}</p>

                  </div>
                </div>

            </div>
          )
        }}
      </MyContext.Consumer>
    );
  }
}

export default Donations;