import React from 'react';
import "./loader.scss"

class Loader extends React.Component {
    render() {
        return (
            <div className={`lds-spinner ${this.props.greenLoader ? "lds-spinner-green" : "lds-spinner"}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>);
    }
}

export default Loader;