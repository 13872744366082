import React from 'react';

import './index.scss';
import Languages from '../../platform/services/languages';
import AppleImg from '../../assets/images/ic_store.png';
import AndroidImg from '../../assets/images/ic_google.png';
import PhoneArm from '../../assets/images/about_arm.png'
import PhoneEng from '../../assets/images/about_eng.png'
import PhoneRus from '../../assets/images/about_rus.png'
import BoxImg from '../../assets/images/charity.svg'
import WalletImg from '../../assets/images/wallet.svg'
import { languageEnum } from '../../platform/services/enums';
import { ROUTES } from '../../platform/constants/routes';
import { Link } from 'react-router-dom';
import { MyContext } from '../../App';

const {
  about_app,
  about_app_box1_title,
  about_app_box2_title,
  about_app_box1_desc1,
  about_app_box1_desc2,
  about_app_box1_desc3,
  about_app_box2_desc,
  about_app_box2_desc2,
  about_app_title1,
  about_app_title2,
  about_app_title3,
  about_app_text1,
  about_app_text2,
  about_app_text3,
} = Languages.Translations

class AboutApp extends React.Component {
  state = {
    selected: +window.localStorage.getItem('language'),
    slideIndex: 1,
  }


  plusSlides = () => {
    if (this.state.slideIndex < 3) {
      this.setState({slideIndex: this.state.slideIndex + 1 })
    } else if (this.state.slideIndex === 3) {
      this.setState({slideIndex: 1 })
    }
  }

  currentSlide = () => {
    if (this.state.slideIndex > 1) {
      this.setState({slideIndex: this.state.slideIndex - 1 })
    } else if (this.state.slideIndex === 1) {
      this.setState({slideIndex: 3 })
    }
  }

  render() {
    const { selected, slideIndex } = this.state;

    return (
      <MyContext.Consumer>
        {(value) =>{
          return (

      <div className="G-page">
        <div className="G-about-page">

          <div className="P-about-top" style={{ backgroundImage: `url(${value.dataImage.about_app_top ? `${value.dataImage.about_app_top}` : ''})` }}></div>

          <div className="P-about-bg G-flex G-justify-between">

            <div className="G-flex G-flex-column P-bg-left" >
              <div className="P-left-box"><h1 className="G-mb-2">{value.data.about_app}</h1>
              <div className="P-slider">
                {slideIndex === 1 ?
                  <div><p className="item-1">{about_app_title1}<p className="G-mt-2">{about_app_text1}</p></p></div> :
                  slideIndex === 2 ?
                    <div><p className="item-2">{about_app_title2} <p className="G-mt-2">{about_app_text2}</p></p></div> :
                    slideIndex === 3 ?
                      <div><p className="item-3">{about_app_title3} <p className="G-mt-2">{about_app_text3}</p></p></div> : ''}


              </div>
              </div>
              {/* <div className="dot-container">
                <span className="dot" ></span>
                <span className="dot" ></span>
                <span className="dot" ></span>
              </div> */}

              <div className="G-mt-4 G-flex P-download-icon">
                <a className="G-mr-4" href="https://play.google.com/store/apps/details?id=com.armboldmind.taponam" target="blank">
                  <img src={AndroidImg} alt="" /></a>
                <a href="https://apps.apple.com/us/app/id1497524947" target="blank">
                  <img src={AppleImg} alt="" /></a>
              </div>
              <div className="prev" onClick={this.currentSlide}>&#10094;</div>
              <div className="next" onClick={this.plusSlides}>&#10095;</div>
            </div>
            <div className="G-flex P-phone-images P-border G-mr-4">
              {selected === languageEnum.hy ? <img className="P-phones" src={PhoneArm} alt="" /> : ''}
              {selected === languageEnum.en ? <img className="P-phones" src={PhoneEng} alt="" /> : ''}
              {selected === languageEnum.ru ? <img className="P-phones" src={PhoneRus} alt="" /> : ''}
              {/* <img className="P-android-phone" src={AndroidPhone} alt=""/> */}
            </div>
          </div>

          <div className="P-boxes G-flex">
            <div className="P-about-box G-mr-6">
              <img src={BoxImg} alt="" />
              <h4>{value.data.download_application_title_paper_waste || 'download_application_title_paper_waste'}</h4>
              <p className="G-mb-2">{value.data.download_application_paper_waste || 'download_application_paper_waste'}</p>
              <p>{value.data.download_application_paper_waste1 || 'download_application_paper_waste1'}<Link className="P-here-word" to={ROUTES.DONATIONS}>{about_app_box1_desc3}</Link></p>
            </div>
            <div className="P-about-box G-ml-6">
              <img src={WalletImg} alt="" />
              <h4>{value.data.download_application_title_stable_income || 'download_application_title_stable_income'}</h4>
              <p>{value.data.download_application_stable_income || 'download_application_stable_income'}<Link className="P-here-word" to={ROUTES.CONTACT_US}>{about_app_box2_desc2}</Link></p>

            </div>
          </div>

        </div>
      </div>
          )
        }}
      </MyContext.Consumer>
    );
  }
}

export default AboutApp;