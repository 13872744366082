import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { ROUTES } from '../../platform/constants/routes';
// import Languages from '../../platform/services/languages'
import { languageEnum } from '../../platform/services/enums';
import LineLoader from '../../platform/lineLoader/index';
import { MyContext } from '../../App';


// const {
//   home,
//   contact_us,
//   about_app,
//   leaderboard,
//   FAQ,
//   projects,
// } = Languages.Translations;


class Header extends React.Component {

  state = {
    whiteHeader       : true,
    scroll            : false,
    headerDonations   : false,
    headerHome        : false,
    headerContact     : false,
    headerLeaderboard : false,
    headerAbout       : false,
    headerFAQ         : false,
    selected          : +window.localStorage.getItem('language'),
    page              : 0,
    size              : 5,
    loader            : false,
  }

  listenScrollEvent = e => {
    if (window.scrollY > 100) {
      this.setState({scroll: true})
    } else {
      this.setState({scroll: false})
    }
  }

  componentDidMount() {
    window.addEventListener('loader', (e)=>{
      this.setState({loader: e.detail})
    })
    this.checkHeader(window.location.pathname)
    window.addEventListener('scroll', this.listenScrollEvent)

    window.routerHistory.listen((data) => {
      this.checkHeader(data.pathname);
    });
  }

  checkHeader = (path) => {
    if (path ===  ROUTES.HOME && this.state.whiteHeader) {
      this.setState({ whiteHeader: false , headerHome: true });
    } else this.setState({  whiteHeader: true , headerHome: false })

    if (path === ROUTES.DONATIONS) {
      this.setState({ headerDonations: true})
    } else this.setState({ headerDonations: false})

    if (path === ROUTES.CONTACT_US) {
      this.setState({ headerContact: true})
    } else this.setState({ headerContact: false})

    if (path === ROUTES.LEADERBOARD) {
      this.setState({ headerLeaderboard: true})
    } else this.setState({ headerLeaderboard: false})

    if (path === ROUTES.ABOUT_APP) {
      this.setState({ headerAbout: true})
    } else this.setState({ headerAbout: false})

    if (path === ROUTES.FAQ) {
      this.setState({ headerFAQ: true})
    } else this.setState({ headerFAQ: false})
  }
  
  // getWinnProjects = async () => {
  //   const { page, size } = this.state
  //   const result = await DonationController.getWinnProjects({page, size});
  //   if (result.success) {
  //     this.setState({ 
  //       donationList: result.data.content,
  //     })
  //   }
  // }

  render() {

    const {headerHome, headerAbout, headerDonations, headerLeaderboard, headerContact, headerFAQ, selected, loader} = this.state
    return (
      <>
        {loader ? <LineLoader/>: null}
        <header 
        className={`${!this.state.whiteHeader && !this.state.scroll ? "P-header-green " : "P-header-white "}`}>
              
          <Link  className="P-header-image" to="/">
            {/* <img src="../../assets/images/logowhite.svg" alt=""/> */}
          </Link>
          <MyContext.Consumer>
            {(value) => {
              return (
                <div className="P-header-link">
                <Link className={`${headerHome ? "G-bold" : ""} ${selected === languageEnum.en ? "P-link-en" : "P-link-am"}`} to="/">{value.data.home || 'home'}</Link>
                <Link className={`${headerAbout ? "G-bold" : ""} ${selected === languageEnum.en ? "P-link-en" : "P-link-am"}`} to={ROUTES.ABOUT_APP}>{value.data.about_app || 'about_app'}</Link>
                <Link className={`${headerDonations ? "G-bold" : ""} ${selected === languageEnum.en ? "P-link-en" : "P-link-am"}`} to={ROUTES.DONATIONS}>{value.data.projects || 'projects'}</Link>
                <Link className={`${headerLeaderboard ? "G-bold" : ""} ${selected === languageEnum.en ? "P-link-en" : "P-link-am"}`} to={ROUTES.LEADERBOARD}>{value.data.leaderboard || 'leaderboard'}</Link>
                <Link className={`${headerFAQ ? "G-bold" : ""} ${selected === languageEnum.en ? "P-link-en" : "P-link-am"}`} to={ROUTES.FAQ}>{value.data.help || 'help'}</Link>
                <Link className={`${headerContact ? "G-bold" : ""} ${selected === languageEnum.en ? "P-link-en" : "P-link-am"}`} to={ROUTES.CONTACT_US}>{value.data.contact_us || 'contact_us'}</Link>
              </div>
              )
            }}
         </MyContext.Consumer>
        </header>
      </>
    );
  }
}

export default Header;
