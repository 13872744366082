import React from 'react';

import './index.scss';
import GoldHero from '../../assets/images/ic_hero.svg'
import GeniusHero from '../../assets/images/ic_genius.svg'
import MasterHero from '../../assets/images/ic_master.svg'
import GreenHero from '../../assets/images/ic_green.svg'
import Gold from '../../assets/images/ic_hero.png'
import Master from '../../assets/images/ic_genius.png'
import Genius from '../../assets/images/ic_master.png'
import Green from '../../assets/images/ic_greensm.svg'
import noPic from '../../assets/images/no-photo.png'
import Tree from '../../assets/images/ic_treefull.svg'

import LeaderBoardController from '../../platform/api/leaderboard/leaderboard'
import { SellerTypeEnum, SellerTypeNameEnum } from '../../platform/services/enums';
import Loader from '../../platform/loader/loader'
import Languages from '../../platform/services/languages'
import { MyContext } from '../../App';

const {
  name,
  status,
  trees_saved,
  trees_saved_mobile,
  corporate,
  individual,
  leaderboard,
  leaderboard_text,
} = Languages.Translations;

class Leaderboard extends React.Component {

  state = {
    isMobile            : false,
    loader              : false,
    corporate           : true,
    individualBoardList : [],
    corporateBoardList  : [],
  }

  onResize = () => this.setState({ isMobile: window.innerWidth <= 992 });
  
  getIndividualsList = async () => {
    this.setState({loader: true})
    const result = await LeaderBoardController.getIndividualsList();
    this.setState({loader: false})

    this.setState({individualBoardList: result.data})
    this.setState({sellerTypeEnum: result.data.sellerStatusEnumValue})
  }

  getCorporatesList = async () => {
    const result = await LeaderBoardController.getCorporatesList();

    this.setState({corporateBoardList: result.data})
    this.setState({sellerTypeEnum: result.data.sellerStatusEnumValue})
  }


  componentDidMount = () => {
    this.getIndividualsList();
    this.getCorporatesList();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  changeCorporate = () => {
    this.setState({ corporate: true })
  }

  changeIndividual = () => {
    this.setState({ corporate: false })
  }

  render() {
    const {loader, isMobile} = this.state;
    
    return (
<MyContext.Consumer>
{(value) => {
  return (
    
      <div className="G-page">

        <div className="P-top" style={{ backgroundImage: `url(${value.dataImage.leaderboard_top ? `${value.dataImage.leaderboard_top}` : ''})` }}>
        </div>

        <div className="P-bg G-flex ">
          <div className="G-flex G-flex-column P-bg-left">
            <div className="P-left-box">
              <h1 className="G-mb-2">{value.data.leaderboard || 'leaderboard'}</h1>
              <p>{value.data.leaderboard_description || 'leaderboard_description'}</p>
              <div className="G-flex G-align-center G-mt-1 P-bg-status">
                <div className="G-flex G-align-center P-bg-status-item">
                  <img className="P-small-icon" src={GreenHero} alt="" /><p className="G-mr-2">{SellerTypeNameEnum[1]}</p>
                  <img className="P-small-icon" src={GeniusHero} alt="" /><p className="G-mr-2">{SellerTypeNameEnum[2]}</p>
                </div>
                <div className="G-flex G-align-center P-bg-status-item">
                  <img className="P-small-icon" src={MasterHero} alt="" /><p className="P-bg-genius">{SellerTypeNameEnum[3]}</p>
                  <img className="P-small-icon" src={GoldHero} alt="" /><p>{SellerTypeNameEnum[4]}</p>
                </div>
              </div>
            </div>
            <div className="P-leader-btn">
              <button className={`${!this.state.corporate ? "P-none-border" : "G-bold"}`} onClick={this.changeCorporate}>{corporate}</button>
              <button className={`${this.state.corporate ? "P-none-border" : "G-bold"}`} onClick={this.changeIndividual}>{individual}</button>
            </div>
          </div>

          <div className="P-bg-right">
            <div className="P-border G-mt-4">
              <img className="P-hero1" src={GoldHero} alt="" />
              <img className="P-hero2" src={GeniusHero} alt="" />
              <img className="P-hero3" src={MasterHero} alt="" />
              <img className="P-hero4" src={GreenHero} alt="" />
            </div>
          </div>
        </div>

        <div className="P-leader-table">
            <div className="P-leaderboard">
              <div className="P-leader-header">
                <span className="P-leader-first">#</span>
                <span className="G-mr-2 P-header-name">{name}</span>
                <span className="G-break-word P-status">{status}</span>
                {/* <span>{!isMobile ? cardboard_weight : weight}</span> */}
                <span className="G-break-word">{!isMobile ? trees_saved : trees_saved_mobile}</span>
              </div>

                {!loader ?
                  !this.state.corporate ?
                    this.state.individualBoardList.map((item, ind) => {
                      return (
                        <div className="P-leaderboard-list" key={ind}>
                          <span className="P-leader-first">{ind + 1}</span>
                          <div className="P-name-status">
                            <div className="G-break-word G-mr-2 G-flex P-list-name">
                              <div className="P-image" style={{ background: `url(${item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}></div>
                              <b>{item.fullName}</b>
                            </div>
                            <span className="G-flex G-align-center G-mr-1 P-list-status">
                              <img src={item.sellerStatusEnumValue === SellerTypeEnum.CITIZEN ? Green : 
                                        item.sellerStatusEnumValue === SellerTypeEnum.MASTER ? Master :
                                        item.sellerStatusEnumValue === SellerTypeEnum.GENIUS ? Genius :
                                        item.sellerStatusEnumValue === SellerTypeEnum.HERO ? Gold : null} alt="" /> 
                              <p>{item.sellerStatusEnumValue === SellerTypeEnum.CITIZEN ? SellerTypeNameEnum[1] : 
                                  item.sellerStatusEnumValue === SellerTypeEnum.MASTER ? SellerTypeNameEnum[2] :
                                  item.sellerStatusEnumValue === SellerTypeEnum.GENIUS ? SellerTypeNameEnum[3] :
                                  item.sellerStatusEnumValue === SellerTypeEnum.HERO ? SellerTypeNameEnum[4] : "-"}
                              </p>
                            </span>
                          </div>
                          {/* <span><b>{item.cardboardAmount !== null ? item.cardboardAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-"}</b></span> */}
                          <span className="G-flex G-align-center">
                          <img className="P-tree-image" src={Tree} alt="" />
                            <p className="P-tree-count">{item.pcsTree !== null ? parseFloat(item.pcsTree.toFixed(2)) : "-"}</p>
                          </span>
                        </div>
                      )
                    })
                    :
                    this.state.corporateBoardList.map((item, corp) => {
                      return (
                        <div className="P-leaderboard-list" key={corp}>
                          <span className="P-leader-first">{corp + 1}</span>
                          <div className="P-name-status">
                            <div className="G-break-word G-mr-2 G-flex P-list-name">
                              <div className="P-image" style={{ background: `url(${item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}></div>
                              <b>{item.fullName}</b>
                            </div>
                            <span className="G-flex G-align-center G-mr-1 P-list-status">
                              <img src={item.sellerStatusEnumValue === SellerTypeEnum.CITIZEN ? Green : 
                                        item.sellerStatusEnumValue === SellerTypeEnum.MASTER ? Master :
                                        item.sellerStatusEnumValue === SellerTypeEnum.GENIUS ? Genius :
                                        item.sellerStatusEnumValue === SellerTypeEnum.HERO ? Gold : null} alt="" /> 
                              <p>{item.sellerStatusEnumValue === SellerTypeEnum.CITIZEN ? SellerTypeNameEnum[1] : 
                                  item.sellerStatusEnumValue === SellerTypeEnum.MASTER ? SellerTypeNameEnum[2] :
                                  item.sellerStatusEnumValue === SellerTypeEnum.GENIUS ? SellerTypeNameEnum[3] :
                                  item.sellerStatusEnumValue === SellerTypeEnum.HERO ? SellerTypeNameEnum[4] : "-"}
                              </p>
                            </span>
                          </div>
                          {/* <span><b>{item.cardboardAmount !== null ? item.cardboardAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-"}</b></span> */}
                          <span className="G-flex G-align-center">
                            <img className="P-tree-image" src={Tree} alt="" />
                            <b>{item.donatedAmount !== null ? parseFloat(item.pcsTree.toFixed(2)) : "-"}</b>
                          </span>
                        </div>
                      )
                    })
               :<div className="P-loader"> <Loader greenLoader={true}/></div> }
  
            </div>
        </div>
      </div>
  )
}}
</MyContext.Consumer>
    );
  }
}

export default Leaderboard;