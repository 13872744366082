import React from 'react';

class Web extends React.Component {

  state = {
    isWeb: false,
  }


  changeWeb = () => {
    if (window.innerWidth > 560) {
      this.setState({ isWeb: true})
    } else this.setState({ isWeb: false})
  }

  componentDidMount() {
    window.addEventListener('resize', (event) => {
      this.changeWeb();
    });
    this.changeWeb();
  }

  render() {
    return(
      this.state.isWeb ? this.props.children : null
    )
  }
}

export default Web;