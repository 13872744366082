import React from 'react';

import './index.scss';
import { Link } from 'react-router-dom';
import LangIcon from '../../assets/images/Ic_down.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import InstaIcon from '../../assets/images/instagram.svg'
import ABM from '../../assets/images/ic_armboldmind.png'
import PrivacyArm from '../../assets/pdf/privacy-ARM.pdf'
import PrivacyEng from '../../assets/pdf/privacy-ENG.pdf'
import PrivacyRu from '../../assets/pdf/privacy-RU.pdf'
import TermsArm from '../../assets/pdf/terms-ARM.pdf'
import TermsEng from '../../assets/pdf/terms-ENG.pdf'
import TermsRu from '../../assets/pdf/terms-RU.pdf'
import { ROUTES } from '../../platform/constants/routes';
import { languageEnum, languageSelected } from '../../platform/services/enums';
import ClickOutSide from '../../platform/click-outside/ClickOutside'
import Languages from '../../platform/services/languages'
import moment from 'moment';
import { MyContext } from '../../App';

const {
  terms_and_conditions,
  privacy_policy,
} = Languages.Translations;

class Footer extends React.Component {

  state = {
    date: new Date(),
    toolbar: false,
    shadowFooter: false,
    selected: +window.localStorage.getItem('language'),
  }

  componentDidMount() {
    this.checkFooter(window.location.pathname)

    window.routerHistory.listen((data) => {
      this.checkFooter(data.pathname);
    });
  }

  checkFooter = (path) => {
    if ((path === ROUTES.CONTACT_US || path === ROUTES.LEADERBOARD || path === ROUTES.ABOUT_APP || path === ROUTES.FAQ) && !this.state.shadowFooter) {
      this.setState({ shadowFooter: true });
    } else if (path !== ROUTES.CONTACT_US && path !== ROUTES.LEADERBOARD && path !== ROUTES.ABOUT_APP && path !== ROUTES.FAQ && this.state.shadowFooter) {
      this.setState({ shadowFooter: false });
    }
  }

  toggleToolbar = () => {
    const { toolbar } = this.state;
    this.setState({ toolbar: !toolbar })
  }
  closeToolbar = () => {
    this.setState({ toolbar: false })
  }

  changeLanguage = (shortCode) => {
    window.localStorage.setItem('language', shortCode);
    window.location.reload();
    window.scrollTo(0, 1);
  }

  render() {

    const {
      selected,
      date,
    } = this.state;

    return (
      <MyContext.Consumer>

        {(value) => {
          return (

            <footer className={`${!this.state.shadowFooter ? "P-footer" : "P-footer-shadow"}`}>
              <div className="P-footer-top">

                <div className="P-footer-left">
                  <Link className="G-bold G-mr-2 G-mb-1" to={selected === languageEnum.hy ? TermsArm :
                    selected === languageEnum.en ? TermsEng :
                      selected === languageEnum.ru ? TermsRu : ""} target="blank">{value.data.terms_and_conditions || 'terms_and_conditions'}</Link>

                  {/* <Link className="G-bold G-mr-2" to={selected === languageEnum.hy ? PrivacyArm :
                    selected === languageEnum.en ? PrivacyEng :
                      selected === languageEnum.ru ? PrivacyRu : ""} target="blank">{value.data.privacy_policy || 'privacy_policy'}</Link> */}
                    <Link className="G-bold G-mr-2" to={ROUTES.PRIVACY}><button><b>{value.data.privacy_policy || 'privacy_policy'}</b></button></Link>
                </div>

                <div className="P-footer-right">

                  <ClickOutSide onClickOutside={this.closeToolbar}>
                    <div className="P-footer-lang" onClick={this.toggleToolbar} >

                      <div className={`${!this.state.toolbar ? "P-lang-hidden" : "P-lang-open"}`}>
                        <div className={`P-languages ${selected === languageEnum.en ? 'P-change-languages' : ''}`}
                          onClick={() => this.changeLanguage(languageEnum.en)}>English</div>
                        <div className={`P-languages ${selected === languageEnum.ru ? 'P-change-languages' : ''}`}
                          onClick={() => this.changeLanguage(languageEnum.ru)}>Русский</div>
                        <div className={`P-languages ${selected === languageEnum.hy ? 'P-change-languages' : ''}`}
                          onClick={() => this.changeLanguage(languageEnum.hy)}>Հայերեն</div>

                      </div>

                      <div className="P-lang-bold G-bold">{languageSelected[selected]}</div>

                      <img src={LangIcon} alt="" />
                    </div>
                  </ClickOutSide>

                  <div className="P-footer-icon">
                    <a href="https://www.facebook.com/tapon.am/?__tn__=%2Cd%2CP-R&eid=ARA00YgQRS_4m_72dNBD0wuOua_YQ63-o5KgsS_FDir0S11ZTeXeCT43YVPC6XNU6PakgMqU1WVrFhTz" target="blank"><img src={FacebookIcon} alt="" /></a>
                    <a href="https://www.instagram.com/tapon.am/" target="blank"><img src={InstaIcon} alt="" /></a>
                  </div>
                </div>

              </div>

              <div className="P-footer-bottom">
                <p>COPYRIGHT © {moment(date).format('YYYY')} TAPON.AM ALL RIGHTS RESERVED</p>
                {/* <a href="https://www.armboldmind.com/" target="blank">Developed by<img src={ABM} alt="" /></a> */}
                <div></div>
              </div>
            </footer>
          )
        }}
      </MyContext.Consumer>
    );
  }
}

export default Footer;
