import React from 'react';
import { ROUTES } from '../../platform/constants/routes';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './index.scss';
import ClickOutSide from '../../platform/click-outside/ClickOutside'
import Web from '../../components/web/web'
import Mobile from '../../components/mobile/mobile'
import UserImg from '../../assets/images/default-user.png'
import EmptyImg from '../../assets/images/odaparik.gif'
import DonationController from '../../platform/api/donations/donations'
import Languages from '../../platform/services/languages'
import Paging from '../../platform/pagination/pagination';
import { MyContext } from '../../App';

const {
  our_donations,
  view_participants,
  participants_list,
  participants,
  amount,
  date,
  have_a_question,
  have_a_question_text,
  contact_form,
  no_search,
  Search,
  close,
  our_donations_text,
  our_donations_text2,
  donations_history,
  projects,
  Donation_empty_title,
  Donation_empty_descr,
} = Languages.Translations;

class Donations extends React.Component {

  state = {
    isOpen: false,
    page: 0,
    size: 8,
    text: '',
    campaignId: null,
    donationList: [],
    participantsList: [],
    inputTimer: null,
    empty: false,
    totalPages: null,
  }


  componentDidMount = () => {
    this.getWinnProjects()
  }
  getWinnProjects = async (pageNum) => {
    const { page, size } = this.state
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await DonationController.getWinnProjects({ page: pageNum ? pageNum.selected : page, size });
    if (result.success) {
      this.setState({
        totalPages: result.data.totalPages,
        donationList: result.data.content,
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }

  getParticipantsList = async (campaignId) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const { text } = this.state
    const result = await DonationController.getParticipantsList(campaignId, text);
    if (result.success) {
      this.setState({ participantsList: result.data, empty: false })
      if (!this.state.participantsList.length) {
        this.setState({ empty: true })
      }
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }

  popupOpen = async (campaignId) => {
    await this.getParticipantsList(campaignId)
    this.setState({ isOpen: true, campaignId: campaignId });
    document.body.style.overflow = "hidden";
  }

  popupClose = () => {
    this.setState({ isOpen: false, participantsList: [] });
    document.body.style.overflow = "auto";
  }

  onSearch = async (evt) => {
    const { campaignId, text } = this.state

    await this.setState({ text: evt.target.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.getParticipantsList(campaignId, text);
    }, 300);
  }

  render() {

    const { donationList, participantsList, text, empty, totalPages } = this.state
    return (
      <MyContext.Consumer>
        {(value) => {
          return (

            <div className="G-page">
              <div className="P-bg-image G-flex G-flex-column G-justify-center" style={{ backgroundImage: `url(${value.dataImage.projects_top ? `${value.dataImage.projects_top}` : ''})` }}>
                <div className="P-left-box G-mb-2"><h1 className="">{value.data.public_benefit_programs_title || 'public_benefit_programs_title'}</h1></div>
                {/* <p>{our_donations_text}</p> */}
              </div>

              <Web>
                <div className="P-donations">
                  <h4 className="G-mb-2">{value.data.public_benefit_programs_title || 'public_benefit_programs_title'}</h4>
                  <p className="P-donation-text G-mb-2">{value.data.public_benefit_programs || 'public_benefit_programs'}</p>
                  <p className="P-donation-text G-mb-6">{value.data.public_benefit_programs1 || 'public_benefit_programs1'}</p>

                  {donationList.length ? <div>
                    <h4 className="P-history-title G-mb-3">{donations_history}</h4>
                    <div className="P-danations-header">
                      <span>{projects}</span>
                      <span>{participants}</span>
                      <span>{amount}</span>
                      <span className="P-danations-date">{date}</span>
                    </div>

                    {
                      donationList.map((item, i) => {
                        return (
                          <div className="G-flex P-donations-list" key={i}>
                            <span className="G-mr-1"><b>{item.projectName}</b></span>
                            <span><b>{item.participants}</b><button onClick={() => this.popupOpen(item.campaignId)} className="G-ml-1 G-green">{value.data.view_paticipants_table || 'view_paticipants_table'}</button></span>
                            <span><b>{item.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <i className="icon-tapon"></i></b></span>
                            <span className="P-danations-date"><b>{moment(item.finishedDate).format('DD.MM.YYYY')}</b></span>
                          </div>
                        )
                      })
                    }

                    <Paging
                      totalPages={totalPages}
                      onChange={this.getWinnProjects}
                    />
                  </div> :

                    <div className="P-empty-donation">
                      <div className="P-relative">
                        <img src={EmptyImg} alt="" />
                        {/* <div className="P-blur-yellow"></div>
                <div className="P-blur-green"></div> */}
                      </div>
                      <div className="P-empty-texts">
                        <h4 className="G-mb-2 G-mt-4">{Donation_empty_title}</h4>
                        <p>{Donation_empty_descr}</p>
                      </div>
                    </div>
                  }
                </div>
              </Web>

              <Mobile>
                <>
                  {donationList.length ?
                    <div className="P-donations">
                      <div className="P-danations-header">
                        <p>{projects}</p>
                        <p>{date}</p>
                      </div>

                      {
                        donationList.map((item, i) => {
                          return (
                            <div className="G-flex P-donations-list" key={i}>
                              <div className="P-mobile-list">
                                <p><b>{item.projectName}</b></p>
                                <p className="G-flex G-align-center">
                                  <b>{item.participants}</b>
                                  <button onClick={() => this.popupOpen(item.campaignId)} className="G-ml-1 G-green">{view_participants}</button>
                                </p>
                              </div>
                              <div className="G-ml-1 P-mobile-list">
                                <p><b>{moment(item.finishedDate).format('DD.MM.YYYY')}</b></p>
                                <p><b>{item.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}<i className="icon-tapon"></i></b></p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    :
                    <div className="P-empty-donation G-mb-4">
                      <div className="P-relative">
                        <img src={EmptyImg} alt="" />
                      </div>
                      <div className="P-empty-texts">
                        <h4 className="G-mb-2 G-mt-4">{Donation_empty_title}</h4>
                        <p>{Donation_empty_descr}</p>
                      </div>
                    </div>
                  }
                </>
              </Mobile>

              <div className={`${!this.state.isOpen ? "P-not-none" : "P-participants"}`}>
                <ClickOutSide onClickOutside={this.popupClose}>
                  <div className="P-popup">
                    <div className="G-flex G-justify-between G-align-center">
                      <h2>{value.data.view_paticipants_participants_list || 'view_paticipants_participants_list'}</h2>
                      <input type="text" placeholder={Search} value={text} onChange={this.onSearch} />
                    </div>
                    {!empty ?
                      <div className="P-list">
                        {participantsList ?
                          participantsList.map((item, i) => {
                            return (
                              <div className="P-participants-list" key={i}>
                                <div className="G-flex">
                                  <div className="P-participants-img" style={{ background: `url(${item.userImage ? `${item.userImage}/300/300` : UserImg}) center/cover no-repeat` }}></div>
                                  <span><b>{item.userName}</b> </span>
                                </div>
                                <span><b>{item.donatedAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <i className="icon-tapon"></i></b></span>
                              </div>
                            )
                          })
                          : ""}
                      </div> : <div className="P-empty">{no_search}</div>}


                    <div className="G-flex G-justify-end"><button onClick={this.popupClose}>{value.data.close || 'close'}</button></div>
                  </div>
                </ClickOutSide>
              </div>

              <div className="P-footer-bgimage">
                <div className="P-footer-box">
                  <h2>{value.data.any_questions_title || 'any_questions_title'}</h2>
                  <p>{value.data.any_questions_description || 'any_questions_description'}</p>
                  <div className="P-footer-btn">
                    <div className="P-footer-button">
                      <Link to={ROUTES.CONTACT_US}><button><b>{value.data.contact_form_button || 'contact_form_button'}</b>  <i className="icon-next"></i></button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </MyContext.Consumer>
    );
  }
}

export default Donations;