import Connection from '../../services/connection';

class CMController {

  static getPageMedias = async () => {
    return Connection.GET({
      controller:'cm',
      action: `getPageMedias`,
    });
  }
}

export default CMController;