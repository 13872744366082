
import Enviroment from './enviroment';

const languageEnum = {
  1: 'hy',
  2: 'ru',
  3: 'en' 
};

class Connection {
  static createHeaders = () => {
    const HEADERS = new Headers();
    HEADERS.append('Content-Type', 'application/json');
    HEADERS.append("LanguageName", languageEnum[+window.localStorage.getItem('language')] || "en");
    return HEADERS;
  };

  static responseRestructure = async(response) => {
    return new Promise(async resolve => {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then((result) => {
          resolve(result);
        });
      } else response.text().then(resolve);
    });
  };

  static queryFromObject = (obj) => {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && (obj[p] !== null && obj[p] !== undefined && obj[p] !== '')) {
        const value = Array.isArray(obj[p]) ? JSON.stringify(obj[p]) : obj[p];
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value));
      }
    }

    return str.join("&");
  };

  static POST = async(data) => {
    const { controller, action, query, body } = data;
    const onlyQuery = (!action && query);
    const HEADERS = Connection.createHeaders();
    try {
      const response = await fetch(`${Enviroment.BASE_URL}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: HEADERS,
      });
      return await Connection.responseRestructure(response);
    } catch (e) {
      return null;
    }
  };

  static PUT = async(data) => {
    const { controller, action, body, query } = data;
    const onlyQuery = (!action && query);
    const HEADERS = Connection.createHeaders();
    try {
      const response = await fetch(`${Enviroment.BASE_URL}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        body: JSON.stringify(body),
        method: 'PUT',
        headers: HEADERS,
      });

      return await Connection.responseRestructure(response);
    } catch (e) {
      return null;
    }
  };

  static DELETE = async(data) => {
    const { controller, action, body, query } = data;

    const onlyQuery = (!action && query);
    const HEADERS = Connection.createHeaders();

    try {
      const response = await fetch(`${Enviroment.BASE_URL}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        body: JSON.stringify(body),
        method: 'DELETE',
        headers: HEADERS,
      });

      return await Connection.responseRestructure(response);
    } catch (e) {
      return null;
    }
  };

  static GET = async (data) => {
    const { controller, action, query } = data;
    const onlyQuery = !action && query;
    const HEADERS = Connection.createHeaders();
    try {
      const response = await fetch(`${Enviroment.BASE_URL}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
        method: 'GET',
        headers: HEADERS,
      });

      return await Connection.responseRestructure(response);
    } catch (e) {
      return null;
    }
  };
}

export default Connection;