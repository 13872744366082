import * as React from 'react';
import "./index.scss";

class LineLoader extends React.Component {

  render() {
    return (
      <div className="P-loader-wrap">
        <div className="P-loader-item">
          <span></span>
          <span></span>
        </div>
        <div className="P-loader-item">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}

export default LineLoader;





