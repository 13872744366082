import React from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Languages from '../../../platform/services/languages';
import Icon from '../../../assets/images/pin.svg'
// import Phone from '../../../assets/images/phone.svg'
import Place from '../../../assets/images/placeholder.svg'
import './index.scss';

const {
  Map_address1,
  Map_address2,
  Address,
} = Languages.Translations

export class MapContainer extends React.Component {

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    address1: false,
    address2: false,
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      address2: false,
      address1: true,
    });

  onMarkerClick1 = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      address2: true,
      address1: false,
  });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  render() {

    return (
      <Map google={this.props.google}
        onClick={this.onMapClicked}
        zoom={10.5}
        initialCenter={{ lat: 40.131299, lng: 44.477281 }}>
        <Marker onClick={this.onMarkerClick}
          name={'Grand Holding'}
          icon={Icon}
          position={{ lat: 40.0659516, lng: 44.4211558 }}
        />
        <Marker onClick={this.onMarkerClick1}
          name={'Grand Holding'}
          icon={Icon}
          position={{ lat: 40.146991, lng: 44.499474 }}
        />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
          <div>
            <div className="P-map-address">
              <img src={Place} alt="" />
              <div>
                <h6>{Address}</h6>
                {this.state.address1 ? <p>{Map_address2}</p>: ""}
                {this.state.address2 ? <p>{Map_address1}</p>: ""}
              </div>
            </div>
              {/* <div className="P-map-phone">
              <img src={Phone} alt="" />
              <div>
                <h6>PHONE NUMBER</h6>
                <p>+37423640042</p>
              </div>
            </div> */}
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyC8G7V6MGzigq5jmvKhvTpqA-n6T_8th-Q')
})(MapContainer)