import React from 'react';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { ROUTES } from './platform/constants/routes';
import Home from './pages/Home/Home';
import AboutApp from './pages/about-app/about-app'
import ContactUs from './pages/contact-us/Contact-us';
import Donation from './pages/donations/donations'
import Leaderboard from './pages/leaderboar/leaderboard';
import FAQ from './pages/FAQ/faq'
import PrivacyPolicy from './pages/privacy-policy/privacy-policy'
import MobileHeader from './components/header/component/mobile';
import KeyController from './platform/api/key/key'
import CMController from './platform/api/cm/cm'


export const MyContext = React.createContext("")
window.routerHistory = createBrowserHistory();

class App extends React.Component {

  state = {
    screen: false,
    _resourses: {},
    _resourseImages: {},
  }


  changeHeader = () => {
    if (window.innerWidth < 1030) {
      this.setState({ screen: true})
     } else this.setState({ screen: false})
  }
  
  componentDidMount() {
    this.getAllKeys();
    this.getPageMedias();
    window.routerHistory.listen(() => {
      window.scrollTo(0, 0);
    });
    window.addEventListener('resize', (event) => {
      this.changeHeader();
    });
    this.changeHeader();
  }
  
  getAllKeys = async () => {
    const result = await KeyController.getAllKeys();
    if (result) {
      this.setState({_resourses: result.data})
    }
  }

  getPageMedias = async () => {
    const result = await CMController.getPageMedias();
    if (result) {
      this.setState({_resourseImages: result.data})
    }
  }
 

  render() {
    const {_resourses, _resourseImages} = this.state;
    return (
      <MyContext.Provider value={{data:this.state._resourses, dataImage: _resourseImages}}>
      <Router history={window.routerHistory}>
        {_resourses && _resourseImages ? <div className="P-main">
           {!this.state.screen ? <Header/> : <MobileHeader />}
            <Switch>
              <Route exact path={ROUTES.HOME} component={Home} />
              <Route exact path={ROUTES.ABOUT_APP} component={AboutApp} />
              <Route exact path={ROUTES.CONTACT_US} component={ContactUs} />
              <Route exact path={ROUTES.LEADERBOARD} component={Leaderboard} />
              <Route exact path={ROUTES.DONATIONS} component={Donation} />
              <Route exact path={ROUTES.FAQ} component={FAQ} />
              <Route exact path={ROUTES.PRIVACY} component={PrivacyPolicy} />
              <Redirect to={ROUTES.HOME} />
            </Switch>
          <Footer />
        </div> : ''}
      </Router>
      </MyContext.Provider>
    );
  }
}

export default App;
