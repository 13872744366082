import Connection from '../../services/connection';

class KeyController {

  static getAllKeys = async () => {
    return Connection.GET({
      controller:'key',
      action: `getAllKeysForWeb`,
    });
  }
}

export default KeyController;