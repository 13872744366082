import EN from '../../assets/translations/en';
import RU from '../../assets/translations/ru';
import AM from '../../assets/translations/am';

const languageEnum = {
  hy : 1,
  ru : 2,
  en : 3
};

const getTranslations = shortCode => {
  switch (shortCode) {
    case languageEnum.ru: return RU;
    case languageEnum.en: return EN;
    default  : return AM;
  } 
};

if (!window.localStorage.getItem('language')) {
  window.localStorage.setItem('language', languageEnum.hy);
}

class Languages {
  static get ShortCode() { return +window.localStorage.getItem('language') || languageEnum.hy; }
  static get Translations() { return getTranslations(Languages.ShortCode); }
}

export default Languages;