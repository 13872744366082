import Languages from "./languages";

const {
  Eco_citizen,
  Eco_master,
  Eco_genius,
  Eco_hero,
} = Languages.Translations;
export const languageEnum = {
  hy: 1,
  ru: 2,
  en: 3,
};

export const languageSelected = {
  1: 'Հայերեն',
  2: 'Русский',
  3: 'English'
};

export const SellerTypeEnum  = {
  CITIZEN : 1, 
  MASTER  : 2, 
  GENIUS  : 3, 
  HERO    : 4
};

export const SellerTypeNameEnum  = {
  1: `${Eco_citizen}`,
  2: `${Eco_master}`,
  3: `${Eco_genius}`,
  4: `${Eco_hero}`
};
