import React from 'react';
import Languages from '../../platform/services/languages'
import FaqList from '../FAQ/component/index'
import Image1 from '../../assets/images/help-img/Image_1.jpg'
import Image2 from '../../assets/images/help-img/Image_2.jpg'
import Image3 from '../../assets/images/help-img/Image_3.jpg'
import MapEN from '../../assets/images/help-img/map_en.jpg'
import MapRU from '../../assets/images/help-img/map_ru.jpg'
import MapHY from '../../assets/images/help-img/map_hy.jpg'
import PaperEN from '../../assets/images/help-img/paper_en.jpg'
import PaperRU from '../../assets/images/help-img/paper_ru.jpg'
import PaperHY from '../../assets/images/help-img/paper_hy.jpg'
import WeightEN from '../../assets/images/help-img/weight_en.jpg'
import WeightRU from '../../assets/images/help-img/weight_ru.jpg'
import WeightHY from '../../assets/images/help-img/weight_hy.jpg'
import ApproximateEN from '../../assets/images/help-img/approximate_en.jpg'
import ApproximateRU from '../../assets/images/help-img/approximate_ru.jpg'
import ApproximateHY from '../../assets/images/help-img/approximate_hy.jpg'
import { languageEnum } from '../../platform/services/enums';
import FAQController from '../../platform/api/faq/faq'
import { MyContext } from '../../App';

import './index.scss';

const {
  asked_questions,
  help_text1,
  help_text2,
  help_text3,
  help_text4,
  help_text5,
  help_text6,
  help_text7,
  asked_questions_top,
} = Languages.Translations;
class FaqPage extends React.Component {

  state = {
    display: null,
    selected: +window.localStorage.getItem('language'),
    loader: false,
    faqList: [],
  }

  componentDidMount = () => {
    this.getAllFAQs();
  }

  toggle = () => {
    this.setState({
      display: !this.state.display
    })
  }

  getAllFAQs = async () => {
    this.setState({ loader: true })
    const result = await FAQController.getAllFAQs();
    this.setState({ loader: false })
    this.setState({ faqList: result.data })
  }


  render() {
    const { selected, faqList } = this.state;

    return (
<MyContext.Consumer>
{(value) => {
  return (
    
      <div className="G-page">

        <div className="P-questions-page" >
          <div className="P-faq-bg G-flex " style={{ backgroundImage: `url(${value.dataImage.help_top ? `${value.dataImage.help_top}` : ''})` }}>
            <div className="G-flex G-flex-column G-justify-center P-bg-left">
              <div className="P-left-box"><h1 className="">{value.data.help_how_to_collect_paper_waste || 'help_how_to_collect_paper_waste'}</h1></div>
              {/* <p>{asked_questions_desc}</p> */}
            </div>
          </div>

          <div className="P-faq-help G-mt-6 G-mb-4">

            <div className="P-row-one G-flex G-justify-around G-mb-6">
              <div className="P-help-item">
                <img src={selected === languageEnum.en ? PaperEN : selected === languageEnum.ru ? PaperRU : selected === languageEnum.hy ? PaperHY : ""} alt="" />
                <p>{value.data.help_step1 || 'help_step1'}</p>
              </div>
              <div className="P-help-item">
                <img src={Image1} alt="" />
                <p>{value.data.help_step2 || 'help_step2'}</p>
              </div>
              <div className="P-help-item">
                <img src={Image2} alt="" />
                <p>{value.data.help_step3 || 'help_step3'}</p>
              </div>
              <div className="P-help-item">
                <img src={selected === languageEnum.en ? WeightEN : selected === languageEnum.ru ? WeightRU : selected === languageEnum.hy ? WeightHY : ""} alt="" />
                <p>{value.data.help_step4 || 'help_step4'}</p>
              </div>
            </div>

            <div className="P-row-two G-flex G-justify-around">
              <div className="P-help-item">
                <img src={selected === languageEnum.en ? ApproximateEN : selected === languageEnum.ru ? ApproximateRU : selected === languageEnum.hy ? ApproximateHY : ""} alt="" />
                <p>{value.data.help_step5 || 'help_step5'}</p>
              </div>
              <div className="P-help-item">
                <img src={selected === languageEnum.en ? MapEN : selected === languageEnum.ru ? MapRU : selected === languageEnum.hy ? MapHY : ""} alt="" />
                <p>{value.data.help_step6 || 'help_step6'}</p>
              </div>
              <div className="P-help-item">
                <img src={Image3} alt="" />
                <p>{value.data.help_step7 || 'help_step7'}</p>
              </div>
            </div>

          </div>

          <div className="P-faq-questions">
            <h1 className="G-mb-2">{asked_questions}</h1>
            {/* <p className="P-faq-desc">{asked_questions_desc}</p> */}
            {faqList.map((item, index) => {
              return (
                <FaqList title={item.question} description={item.answer} key={index}/>
              )
            })
            }

          </div>
        </div>
      </div>
  )
}}
</MyContext.Consumer>
    )
  }
}


export default FaqPage;