import React from 'react';
import { Link } from 'react-router-dom';
import AppleImg from '../../assets/images/apple.png';
import AndroidImg from '../../assets/images/android.png';
// import UserImg from '../../assets/images/default-user.png';
import AltImg from '../../assets/images/glxavor.gif';
import VideoItem from '../../assets/video/TAPON.am.mp4';

import './index.scss';
import { ROUTES } from '../../platform/constants/routes';
import AppearAnimation from '../../components/appear-animation';
import ClickOutSide from '../../platform/click-outside/ClickOutside';
import Web from '../../components/web/web';
import Mobile from '../../components/mobile/mobile';
import Languages from '../../platform/services/languages';
import DonationController from '../../platform/api/donations/donations';
import moment from 'moment';
import { languageEnum } from '../../platform/services/enums';
import { MyContext } from '../../App';

const {
  tapon_connects,
  join_our_community,
  about_us_text,
  about_us_text_2,
  our_donations,
  view_participants,
  view_all,
  participants_list,
  projects,
  participants,
  amount,
  date,
  have_a_question,
  have_a_question_text,
  contact_form,
  tapon_connects_text,
  close,
  no_search,
  Search,
  our_donations_hometext,
} = Languages.Translations;

class Home extends React.Component {

  state = {
    isOpen            : false,
    empty             : false,
    page              : 0,
    size              : 5,
    text              : '',
    donationList      : [],
    participantsList  : [],
    totalElements     : 0,
    selected          : +window.localStorage.getItem('language'),
  }

popupOpen = async (campaignId) => {
  await this.getParticipantsList(campaignId)
  this.setState({isOpen: true, campaignId: campaignId});
  document.body.style.overflow = "hidden";
}

popupClose = () => {
  this.setState({isOpen: false, participantsList: []});
  document.body.style.overflow = "auto";
}

onSearch = async (evt) => {
  const { campaignId, text } = this.state

  await this.setState({ text: evt.target.value })
  clearTimeout(this.inputTimer);
  this.inputTimer = setTimeout(() => {
    this.getParticipantsList(campaignId, text);
  }, 300);
}


componentDidMount = () => {
  this.getWinnProjects()
}
getWinnProjects = async () => {
  const { page, size } = this.state;
  window.dispatchEvent(new CustomEvent('loader', { detail: true }));
  const result = await DonationController.getWinnProjects({page, size});
  if (result.success) {
    this.setState({ 
      donationList  : result.data.content,
      totalElements : result.data.totalElements
    })
  }
  window.dispatchEvent(new CustomEvent('loader', { detail: false }));
}

getParticipantsList = async (campaignId) => {
  const {text} = this.state
  window.dispatchEvent(new CustomEvent('loader', { detail: true }));
  const result = await DonationController.getParticipantsList(campaignId, text);
  if (result.success) {
    this.setState({ participantsList: result.data, empty: false })
    if (!this.state.participantsList.length) {
      this.setState({empty: true})
    }
  }
  window.dispatchEvent(new CustomEvent('loader', { detail: false }));
}

  render() {

    const { donationList, participantsList, text, empty, totalElements, selected } = this.state

    return (
      
      <MyContext.Consumer>
        {(value) => {
          return (

        <div className="P-home-page">
          <div className="P-bg-image" style={{ backgroundImage: `url(${value.dataImage.home_top ? `${value.dataImage.home_top}` : AltImg})` }}>
            <div className={`${selected === languageEnum.en ? "P-bg-left" : "P-bg-left P-bg-left-hy"}`}>
            {/* <div className="P-green-line"></div> */}
  
              <div className="P-left-box">
                <div className="P-bg-left-header">
                  <h1> <span className="P-white"></span> {value.data.home_page_title || 'home_page_title'}</h1>
                </div>
                <div className="P-bg-left-desc">
                  <p>{tapon_connects_text}</p>
                </div>
              </div>
              <div className="G-mt-1">
                <p>{join_our_community}</p>
              </div>
              <div className="P-bg-left-icon">
                <a href="https://play.google.com/store/apps/details?id=com.armboldmind.taponam" target="blank">
                  <img src={AndroidImg} alt="" /></a>
                <a href="https://apps.apple.com/us/app/id1497524947" target="blank">
                  <img src={AppleImg} alt="" /></a>
              </div>
            </div>
          </div>
  
          <div className="P-about">
            <div className="P-about-text">
              {/* <AppearAnimation tag="h1"> {about_us} </AppearAnimation> */}
  
              <AppearAnimation tag="p">
                {about_us_text}
                <p className="G-mt-1">{about_us_text_2}</p>
              </AppearAnimation>
            </div>
  
            {/* <AppearAnimation tag="img" src={AboutImg}></AppearAnimation> */}
            <div><video src={VideoItem} autoPlay controls muted playsInline></video></div>
          </div>
  
          {donationList.length ? <div className="P-home-donations">
            <AppearAnimation tag="h1">{value.data.public_benefit_programs_title || 'public_benefit_programs_title'}</AppearAnimation>
            <AppearAnimation tag="p">{our_donations_hometext}</AppearAnimation>
  
            <Web>
              <AppearAnimation tag="div" className="P-donations">
                
                <div className="P-danations-header">
                  <span>{projects}</span>
                  <span>{participants}</span>
                  <span>{amount}</span>
                  <span>{date}</span>
                </div>
    
              {donationList ?
                donationList.map((item, i) =>{
                  return (
                    <div className="G-flex P-donations-list"  key={i}>
                        <span><b>{item.projectName}</b></span>
                        <span><b>{item.participants}</b><button onClick={() => this.popupOpen(item.campaignId)} className="G-ml-1 G-green">{view_participants}</button></span>
                        <span><b>{item.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <i className="icon-tapon"></i></b></span>
                        <span><b>{moment(item.finishedDate).format('DD.MM.YYYY')}</b></span>
                    </div>
                  )
                })
              : ""}
              </AppearAnimation>
            </Web>
  
            <Mobile>
              <AppearAnimation tag="div" className="P-donations">
                  <div className="P-danations-header">
                    <p>{projects}</p>
                    <p>{date}</p>
                  </div>
    
                {donationList ?
                  donationList.map((item, i) =>{
                    return (
                      <div className="G-flex P-donations-list"  key={i}>
                          <div className="P-mobile-list">
                            <p><b>{item.projectName}</b></p>
                            <p><b>{item.participants}</b><button onClick={() => this.popupOpen(item.campaignId)} className="G-ml-1 G-green">{view_participants}</button></p>
                          </div>
                          <div className="G-ml-1 P-mobile-list">
                            <span><b>{moment(item.finishedDate).format('DD.MM.YYYY')}</b></span>
                            <p><b>{item.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <i className="icon-tapon"></i></b></p>
                          </div>
                      </div>
                    )
                  })
                : ""}
              </AppearAnimation>
            </Mobile>
         
            <div className={`${!this.state.isOpen ? "P-not-none" : "P-participants"}`}>
            <ClickOutSide onClickOutside={this.popupClose}>
              <div className="P-popup">
                <div className="G-flex G-justify-between G-align-center">
                  <h2>{participants_list}</h2>
                  <input type="text" placeholder={Search} value={text} onChange={this.onSearch}/>
                </div>
                 {!empty ? 
                 <div className="P-list">
                   {participantsList ? 
                      participantsList.map((item, i) =>{
                        return (
                          <div className="P-participants-list"  key={i}>
                            <div className="G-flex">
                              <div className="P-participants-img" style={{ background: `url(${item.userImage ? `${item.userImage}/300/300` : AltImg}) center/cover no-repeat` }}></div>
                              <span><b>{item.userName}</b> </span>
                            </div>
                            <span><b>{item.donatedAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} <i className="icon-tapon"></i></b></span>
                          </div>
                        )
                      })
                    : ""}
                 </div> : <div className="P-empty">{no_search}</div>}
                <div className="G-flex G-justify-end"><button onClick={this.popupClose}>{close}</button></div>
              </div>
              </ClickOutSide>
            </div>
  
            {donationList && totalElements > 5 ?
              <AppearAnimation tag="div" className="P-donation-btn">
                <Link to={ROUTES.DONATIONS}><button>{view_all}</button></Link>
              </AppearAnimation> : ""}
          </div> : ""}
  
          <div className="P-footer-bgimage" style={{ backgroundImage: `url(${value.dataImage.home_footer ? `${value.dataImage.home_footer}` : AltImg})` }}>
              <div className="P-footer-box">
                <h2>{value.data.any_questions_title || 'any_questions_title'}</h2>
                <p>{value.data.any_questions_description || 'any_questions_description'}</p>
                <div className="P-footer-btn">
                  <div className="P-footer-button">
                    <Link to={ROUTES.CONTACT_US}><button><b>{value.data.contact_form_button || 'contact_form_button'}</b>  <i className="icon-next"></i></button></Link>
                  </div>
                </div>
              </div>
          </div>
        </div>
          )
        }}
      </MyContext.Consumer>
    );
  }
}

export default Home;
