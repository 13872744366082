import React from 'react';

export default {
  language_label        : 'հայ',
  home                  : 'Գլխավոր',
  english               : 'Անգլերեն',
  russian               : 'Ռուսերեն',
  armenian              : 'Հայերեն',
  language              : 'Լեզու',
  contact_us            : "Կապ մեզ հետ",
  donations             : "Հանրօգուտ ծրագրեր",
  FAQ                   : "Օգնություն",
  asked_questions       : "Հաճախ տրվող հարցեր",
  asked_questions_top   : "Ինչպես կուտակել թղթի թափոնը",
  asked_questions_desc  : "Այս Հաճախ տրվող հարցերը տալիս է TAPON.AM- ի վերաբերյալ հիմնական հարցերի պատասխանները",
  question_1            : "1․ Ի՞նչու ես պետք է աղբից առանձնացնեմ թղթի թափոնը, կուտակեմ և հանձնեմ։",
  question_2            : "2. Ո՞վ է գործակալը:",
  question_3            : "3. Ո՞վ է բաժանորդը:",
  question_4            : "4. Հնարավո՞ր է արդյոք մեկ օգտատերը TAPON.AM հավելվածում հանդիսանա և´ գործակալ, և´ բաժանորդ:",
  question_5            : "5. Ի՞նչ է TapPoint-ը:",
  question_6            : "6. Ի՞նչ նպատակով են բաժանորդի մոտ կուտակվում TapPoint միավորներ:",
  question_7            : "7. Ի՞նչ եղանակով են ընտրվում հանրօգուտ ծրագրերը և ինչպես են դրանք իրականացվում:",
  question_8            : "8. Կարո՞ղ է արդյոք հանրօգուտ ծրագրի իրականացումից և միավորների զրոյանալուց հետո բաժանորդն իր քվեարկած միավորները ետ պահանջել կամ փոփոխել իր որոշումը:", 
  question_9            : "9. Կարելի՞ է արդյոք TapPoint միավորները վերածել դրամի:",
  question_10           : "10. Կարելի՞ է արդյոք դրամը վերածել  TapPoint միավորների:", 
  question_11           : "11. Կարելի՞ է արդյոք TapPoint միավորների միջոցով կատարել գնումներ:", 
  question_12           : "12. Ինչպե՞ս կարող է գործակալը հանձնել իր մոտ կուտակված թղթի թափոնը մթերող ընկերությանը:  ", 
  question_13           : "13. Տրամադրու՞մ է արդյոք TAPON.AM հավելվածը գործակալին տրանսպորտային միջոց՝ թղթի թափոնը հավաքագրելու նպատակով:", 
  question_14           : "14. Ի՞նչ անել, որպեսզի գործակալը հաճախակի այցելի Ձեր շենք, բնակարան կամ որևէ այլ թղթի թափոնի հանձնման կետ:", 
  answer_1              : "Այդպես են վարվում քաղաքակիրթ հասարակության անդամները, ինչպես նաև մարդիկ, ովքեր մտածում են շրջակա միջավայրի մաքրության և էկոլոգիայի մասին։",
  answer_2              : "TAPON.AM հավելվածում գործակալ է հանդիսանում այն օգտատերը, ով բաժանորդներից հավաքում է թղթի թափոնը, բաժանորդներին թղթի թափոնի դիմաց տրամադրում TapРoint միավորներ, իսկ հավաքագրած թղթի թափոնը հանձնում մթերող ընկերությանը և դրա դիմաց ստանում գումար:",
  answer_3              : "TAPON.AM հավելվածում բաժանորդ է հանդիսանում այն օգտատերը, ով թղթի թափոնն աղբից առանձին կուտակում և հանձնում է գործակալին՝ դրա դիմաց ստանալով TapPoint միավորներ։ ",
  answer_4              : "TAPON.AM հավելվածում մեկ օգտատերը միաժամանակ և´ գործակալ, և´ բաժանորդ  չի կարող հանդիսանալ:",
  answer_5              : "TapPoint անվանվում են միավորները, որոնք գործակալի կողմից հատկացվում են բաժանորդին հանձնած թղթի թափոնի յուրաքանչյուր կգ-ի դիմաց: Թափոն ձեռքբերելու համար գործակալն իր միավորները ստանում կամ համալրում է հավելվածի միջոցով, սահմանված կարգով։",
  answer_6              : "Հավելվածում գրանցված բոլոր բաժանորդների կողմից կուտակած TapPoint միավորների շնորհիվ իրականացվում են տարբեր հանրօգուտ ծրագրեր:",
  answer_7              : "TAPON.AM հավելվածը տրամադրում է բաժանորդներին հանրօգուտ ծրագրերի ցանկ: Բաժանորդները քվեարկության շնորհիվ ընտրում են միայն մեկ հանրօգուտ ծրագիր, որին պետք է հատկացվի կուտակված TapPoint միավորների չափով աջակցություն։ Նշված ծրագրի իրականացումից հետո բոլոր բաժանորդների կուտակած միավորները զրոյացվում են։",
  answer_8              : "Քվեարկած միավորները ետ պահանջել կամ կատարված որոշումը փոփոխել հնարավոր չէ:",
  answer_9              : "TAPON.AM հավելվածի կամ որևէ այլ հավելվածի միջոցով գործակալին կամ բաժանորդին տրված TapPoint միավորները կանխիկացնել՝ դրամի վերածել հնարավոր չէ: ",
  answer_10             : "TapPoint միավորները տրամադրվում են միայն TAPON.AM հավելվածի կողմից. Որևէ այլ կերպ TapPoint միավոր ստանալ հնարավոր չէ:",
  answer_11             : "TapPoint միավորները ո՛չ դրամական միջոց է, ո՛չ վճարային համակարգ։ TapPoint միավորներով հնարավոր չէ իրականացնել գնումներ։",
  answer_12             : "Գործակալը իր կողմից հավաքագրած թղթի թափոնը մթերող ընկերություն է տեղափոխում կամ իր միջոցներով, կամ համապատասխան քանակ հավաքելուց հետո դիմում է մթերող ընկերությանը բեռնափոխադրումը կազմակերպելու նպատակով: ",
  answer_13             : "TAPON.AM հավելվածը գործակալին տրանսպորտային միջոց չի տրամադրում, քանի որ այն հարթակ է թղթի թափոն կուտակողների, դրանց հավաքն իրականացնողների և մթերող/վերամշակողների համար:",
  answer_14             : "Կուտակել թղթի թափոնը ինքնուրույն, ինչպես նաև TAPON.AM հավելվածի մասին պատմել ձեզ շրջապատող մարդկանց (հարևաններին, շենքի բնակիչներին, գործընկերներին): Խորհուրդ տալ նրանց նույնպես կուտակել թղթի թափոն և օգտվել TAPON.AM հավելվածից, քանի որ միևնույն տեղանքից որքան շատ թղթի թափոնի հանձնման հայտեր բացվեն, այդքան ավելի մեծ է հավանականությունը, որ գործակալը այդ տեղանք կայցելի ավելի հաճախ:",
  tapon_connects        : "ТAPON.AM-ը բջջային հավելված է, որը Ձեզ կօգնի կուտակված թղթի թափոնը հեշտությամբ ուղարկել վերամշակման",
  tapon_connects_text   : "TAPON.AM-ը նախատեսված է բոլորի համար։ Հավելվածից հեշտությամբ կարող են օգտվել թե՛ տանը թղթի թափոնն աղբից առանձին տեսակավորողները, թե՛ այն հավաքողներն ու վերամշակման հաձնողները: Լինելով ակտիվ՝ յուրաքանչյուր օգտատեր մասնակցում է աղբավայրերում աղբի կրճատմանը, շրջակա միջավայրի պահպանությանն ու տարբեր հանրօգուտ ծրագրերի։ Հավելվածի բազմաթիվ օգտատերեր այլեւս ոչ պիտանի թուղթն արդեն ուղարկում են վերամշակման։",
  join_our_community    : "Միացիր մեզ եւ ծանոթացիր Քո կողմից փրկված ծառերի քանակին",
  projects              : "Նախագծեր",
  about_app             : "Հավելվածի մասին",

  about_app_title1      : "Ինչի համար է հավելվածը",
  about_app_title2      : "Ինչպես է այն աշխատում ",
  about_app_title3      : "Ինչպես խթանել թղթի թափոնների մթերման գործընթացը",

  about_app_text1       : "TAPON.AM հավելվածը նախատեսված է թղթի թափոնների հանձնման և մթերման գործընթացն առավել արդյունավետ և դյուրին դարձնելու համար: Հավելվածից օգտվողներն առանց ավելորդ ջանք գործադրելու մասնակցում են շրջակա միջավայրի պահպանության և մաքրության կարևորագույն խնդրի լուծմանը:",
  about_app_text2       : "Հավելվածի մասնակիցներն են բաժանորդները (թղթի թափոնն աղբից առանձին  տեսակավորող և կուտակող քաղաքացիներ և կազմակերպություններ) և գործակալները (մարդիկ, ովքեր բաժանորդներից հավաքում են թղթի թափոնը և հանձնում վերամշակման): Բաժանորդները, նշելով կուտակած թղթի թափոնի քանակը և հասցեն, հավելվածում ստեղծում են հայտ: Գործակալները հավելվածի միջոցով անմիջապես (online ռեժիմով) տեսնում են բոլոր հայտերը, որոնք ստեղծել են բաժանորդները: Կազմելով երթուղի՝ գործակալները հաջորդաբար մոտենում և հավաքում են թղթի թափոնները։",
  about_app_text3       : "Երթուղի կազմելիս գործակալները նախապատվություն են տալիս այն հասցեներին, որտեղ բաժանորդների քանակը մեծ է և պարբերաբար շատ թղթի թափոն է կուտակվում: Եթե ցանկանում եք, որ տանը կամ աշխատավայրում կուտակած թղթի թափոնը երկար չսպասի գործակալին, չմոռանաք հարևաններին և ընկերներին պատմել TAPON.AM-ի մասին: Ձեր հանձնած ընդհանուր թղթի թափոնի քանակը շատ կարևոր է՝ հավելվածը հաշվարկում և Ձեզ տեղեկացնում է, թե ինչ ծավալի օգուտ եք տվել շրջակա միջավայրին:",

  about_app_box1_title  : "Տանը, գրասենյակում կամ մեկ այլ վայրում մշտապես գոյանում է թղթի թափոն։",
  about_app_box2_title  : "Չունեք աշխատանք և/կամ ցանկանում եք կայուն գումար վաստակել։",
  about_app_box1_desc1  : "Ներբեռնիր հավելվածը և գրանցվիր որպես բաժանորդ (թղթի թափոն հանձնող)՝ տեղեկացրու կուտակված  թափոնի մասին և հանձնիր այն առանց հավելյալ ջանքերի։",
  about_app_box1_desc2  : "Յուրաքանչյուր բաժանորդ թղթի թափոն հանձնելիս ստանալու է TapPoint միավորներ: Բոլոր բաժանորդների համատեղ կուտակած միավորներով  պարբերաբար իրականացվելու են նույն բաժանորդների կողմից քվեարկությամբ ընտրված",
  about_app_box1_desc3  : "հանրօգուտ ծրագրեր։",
  about_app_box2_desc   : "Ներբեռնիր հավելվածը և գրանցվիր որպես գործակալ (թղթի թափոն հավաքող)՝ տեղեկացիր բաժանորդների հասցեների և կուտակված թղթի թափոնների քանակների մասին, հերթականությամբ մոտեցիր բաժանորդների հասցեներով, հավաքիր թղթի թափոնները, համագործակցիր  TAPON.AM հավելվածի հետ և  վաճառիր թղթի թափոնը  հավելվածի կողմից երաշխավորված կազմակերպությանը։ Մանրամասները տեսնելու համար՝ գրիր մեզ",
  about_app_box2_desc2  : "այստեղ:",
  about_us              : "Մեր մասին ",
  about_us_text         : "Իսկ Դուք տեղյա՞կ եք, որ Հայաստանի Հանրապետության յուրաքանչյուր քաղաքացի տարեկան միջին հաշվարկով աղբն է նետում մոտ 20 կգ օգտագործված, ոչ պիտանի թուղթ և ստվարաթուղթ։ Հաշվի առնելով Հայաստանի Հանրապետության բնակչության քանակը՝ տարեկան մոտ 58.000.000 կգ թուղթ հայտնվում է աղբավայրերում կամ աղտոտում բնակավայրերը, ինչպես նաև շրջակա միջավայրը։ Տվյալ քանակությամբ թուղթը զբաղեցնում է մոտ 500.000 քմ տարածք։ Պատկերացնելու համար նշենք, որ թղթի թափոնի նման քանակը կզբաղեցնի այնքան տարածք որքան կազմում է Սյունիքի մարզի Դաստակերտ քաղաքը։ Աղբավայրերում տեղ զբաղեցնելուց բացի այն աղտոտում է շրջակա միջավայրը և վնասում ընդհանուր էկոլոգիան։",
  about_us_text_2       : "Այսօր մենք ունենք լավ հնարավորություն թղթի թափոնների հետ քաղաքակիրթ և խելամտորեն վարվելու համար։ Աղբի վերածելու փոխարեն թղթի թափոններից կարելի է ստանալ օգտակար, էկոլոգիապես մաքուր և կենցաղում պահանջարկ վայելող արտադրատեսակներ։",
  our_donations         : "Հանրօգուտ ծրագրեր",
  donation              : "Ծրագրեր",
  our_donations_hometext: "Մեր հավելվածի միջոցով յուրաքանչյուր օգտատեր կկարողանա իր փոքրիկ ներդրումն ունենանալ որևէ մեկին օգնելու հարցում:",
  our_donations_text    : "TAPON.AM հավելվածն իր օգտատերերին առաջարկում է տեսակավորել թղթի թափոնը կենցաղային աղբից և այն տրամադրել վերամշակման։ Հանձնած թղթի թափոնի յուրաքանչյուր կգ-ի դիմաց հավելվածը բաժանորդին հատկացնում է միավորներ․ այսպես կոչված TapPoint-ներ: TAPON.AM-ը պարբերաբար հրապարակելու է հանրօգուտ ծրագրեր, որոնց նպատակն է աջակցել շրջակա միջավայրի խնդիրների լուծմանը: Թափոնը վերամշակման հանձնելով՝ բաժանորդները հնարավորություն են ստանում քվեարկել հավելվածի կողմից հրապարակված հանրօգուտ ծրագրից մեկի օգտին: Քվեարկության արդյունքում առավելագույն ձայներ հավաքած հանրօգուտ ծրագիրը իրականություն կդառնա Ձեր և մնացած բոլոր բաժանորդների կուտակած ընդհանուր միավորների շնորհիվ։ Յուրաքանչյուր բաժանորդ մշտապես կարող է ծնոթանալ ինչպես քվեարկության ընթացքին, այդպես էլ արդյունքներին և կուտակված միավորներին։",
  our_donations_text2   : "Դե ի՞նչ. ընտրությունը Ձերն է՝ դեն նետել այլևս ոչ պիտանի թուղթը՝ վնասելով բնությանը, թե կուտակել այն և տրամադրել վերամշակման՝ օգուտ բերելով բնությանն ու շրջակա միջավայրին:",
  donations_history     : "Հանրօգուտ ծրագրերի պատմություն",
  view_participants     : "դիտել մասնակիցներին",
  view_all              : "Դիտել ամբողջը",
  participants_list     : "Մասնակիցների ցանկ",
  fund                  : "Ֆոնդ",
  participants          : "Մասնակիցներ",
  amount                : "Գումար",
  date                  : "Ամսաթիվ",
  have_a_question       : "Ձեզ մոտ ծագե՞լ են հարցեր",
  have_a_question_text  : "Կապ հաստատեք մեզ հետ և մենք Ձեզ կպատասխանենք որքան հնարավոր է արագ:",
  contact_form          : "Հետադարձ կապի ձևը",
  terms_and_conditions  : "Ընդհանուր դրույթներ և պայմաններ",
  privacy_policy        : "Գաղտնիության քաղաքականություն",
  leaderboard           : "Առաջատարների ցանկ",
  leaderboard_text      : <>Այս բաժնում ներկայացված են այն քաղաքացիները և կազմակերպությունները, ովքեր ամենաակտիվ մասնակցությունն են ունեցել թղթի թափոնների կուտակման գործընթացում: <br/> Իրենք իրենց փոքր ներդրումն ունեն ինչպես շրջակա միջավայրի պահպանման, այնպես էլ հանրօգուտ ծրագրերի իրագործման հարցում: <br/> Թղթի թափոնների կուտակման գործընթացում ակտիվ մասնակցություն ունեցող բոլոր քաղաքացիներին եւ կազմակերպություններին կշնորհվեն կարգավիճակներ՝</>,
  individual            : "Անհատներ",
  corporate             : "Կորպորատիվ",
  name                  : "ԱՆՈւՆ",
  status                : "Կարգավիճակ",
  cardboard_amount      : "Թղթի թափոնի քանակ",
  cardboard_weight      : "Թափոնի քաշ (կգ)",
  weight                : "Քաշ (կգ)",
  donated_amount        : "Նվիրաբերված միավոր",
  trees_saved           : "Փրկված ծառերի քանակ",
  trees_saved_mobile    : "Փրկված ծառեր",
  point                 : "Միավոր",
  contact_us_text       : "Ուղարկեք մեզ ձեր հարցերը կամ առաջարկները, և մենք հնարավորինս արագ կապ կհաստատենք Ձեզ հետ",
  email                 : "Էլ. Փոստի հասցե",
  phone_number          : "Հեռախոսահամար",
  message               : "Հաղորդագրություն",
  send                  : "Ուղարկել",
  close                 : "Փակել",
  message_sent          : "Ձեր հաղորդագրությունը հաջողությամբ ուղարկված է",
  Thank_you_for         : "Շնորհակալություն մեզ հետ կապ հաստատելու համար: Մենք կապ կհաստատենք ձեզ հետ հնարավորինս արագ",
  min_5_max_500         : "(նվազագույնը  5, առավելագույնը 500 նիշ)",
  list_is_empty         : "Ցանկը դատարկ է",
  no_search             : "Ոչինչ չի գտնվել",
  Search                : "Որոնել",
  Eco_citizen           : "էկո քաղաքացի",
  Eco_master            : "էկո վարպետ",
  Eco_genius            : "էկո հանճար",
  Eco_hero              : "էկո հերոս",
  Donation_empty_title  : <>Այս պահին իրականացված ծրագրեր չկան</>,
  Donation_empty_descr  : <>Կապի մեջ մնացեք մեզ հետ՝ <br/> թարմացված տվյալներին ծանոթանալու համար։ <br/> Շնորհակալություն</>,
  Map_address1          : "Հրանտ Վարդանյան փող. 8/2, Երևան",
  Map_address2          : "Հրանտ Վարդանյան փող. 4/5, Մասիս",
  Address               : "ՀԱՍՑԵ",

  errorMessages: {
    name_error          : "Ձեր անունը պետք է պարունակի առնվազն 4 նիշ",
    email_error         : "էլ. փոստի հասցեն պետք է պարունակի առնվազն 5 նիշ և @ նշանը",
    phone_number_error  : "Խնդրում ենք նշել Ձեր հեռախոսահամարը (+374XXXXXXXX)",
    message_error       : "Խնդրում ենք գրել ձեր հաղորդագրությունը (նվազագույնը  5, առավելագույնը 500 նիշ)",
  },

  help_text1            : "Կուտակեք թղթի և ստվարաթղթի  մնացորդներն ու թափոններն աղբից առանձին",
  help_text2            : "Կուտակված թղթի թափոնները կապեք կամ տեղավորեք ստվարաթղթից արկղի, թղթե կամ պոլիէթիլենային պարկի մեջ՝ սեղմելով և խտացնելով դրանք։",
  help_text3            : "Կուտակված թղթի թափոնները խտացված վիճակում կշռեք, այնուհետև արկղի կամ պարկի վրա կատարեք նշում քաշի վերաբերյալ։",
  help_text4            : "Տվյալ պահին ՁԵզ մոտ կուտակված թղթի թափոնի քաշը գրանցեք TAPON.AM հավելվածում։ Հավելվածի մեջ գրանցեք օրվա ընթացքում  թափոնը հանձնելու Ձեզ հարմար ժամերը։",
  help_text5            : "Մինչ թափոնի հանձնելը Ձեզ մոտ ավելացե՞լ է թափոնի լրացուցիչ քանակ։ Պետք չէ նոր հայտ գրանցել։ Բացեք արդեն իսկ գրանցած հայտը և փոխարինեք նախկին քաշը նորով՝ նշելով նախկինում գրանցած և նոր թափոնի քաշերի գումարը։",
  help_text6            : "Հետևեք թղթի թափոն մթերող գործակալի հաղորդագրություններին։ Նշված ժամին նշված վայրում հանձնեք թղթի թափոնները, ստացեք և կուտակեք միավորներ։",
  help_text7            : "Հավելվածի միջոցով մասնակցեք քվեարկության, ընտրեք առաջարկվող հանրօգուտ ծրագրերից որևէ մեկը։ Ձեր և մնացած բոլոր մասնակիցների կուտակած միավորներով կյանքի կկոչվի քվեարկության հաղթող ճանաչված ծրագիրը։",
};