import React from 'react';

export default {
  language_label        : 'py',
  home                  : 'Главная',
  english               : 'Английский',
  russian               : 'Русский',
  armenian              : 'Армянский',
  language              : 'Язык',
  contact_us            : 'Контакты',
  donations             : "Общеполезные программы",
  FAQ                   : "Помощь",
  asked_questions       : "Часто задаваемые вопросы",
  asked_questions_top   : "Как собирать бумажные отходы?",
  asked_questions_desc  : "В данном разделе представлены ответы на основные вопросы о TAPON.AM",
  question_1            : "1. Почему я должен отделять бумажные отходы от мусора, собирать их и сдавать?",
  question_2            : "2. Кто такой агент?",
  question_3            : "3. Кто такой абонент?",
  question_4            : "4. Может ли один пользователь в приложении TAPON.AM быть одновременно и агентом, и абонентом?",
  question_5            : "5. Что такое TapPoint?",
  question_6            : "6. Какова цель накопления абонентами баллов TapPoint?",
  question_7            : "7. Каким образом выбираются общеполезные программы и как они реализуются?",
  question_8            : "8. Может ли абонент запросить обратно свои баллы или изменить свое решение после реализации общеполезного проекта и обнуления баллов?", 
  question_9            : "9. Можно ли конвертировать баллы TapPoint в драмы?",
  question_10           : "10. Можно ли конвертировать драмы в баллы TapPoint?", 
  question_11           : "11. Возможно ли совершать покупки, используя баллы TapPoint?", 
  question_12           : "12. Как агент может передать накопленные им бумажные отходы компании-закупщику?", 
  question_13           : "13. Предоставляет ли приложение TAPON.AM транспортное средство для сбора бумажных отходов?", 
  question_14           : "14. Что нужно делать, чтобы агент часто посещал Ваше здание, квартиру или любую другую точку для сдачи бумажных отходов?", 
  answer_1              : "Таким образом поступают члены цивилизованных обществ, кроме того так поступают те люди, которые думают об окружающей среде и экологии.",
  answer_2              : "В приложении TAPON.AM агент - это пользователь, который собирает бумажные отходы от абонентов, предоставляет им баллы TapPoint за них, а собранные бумажные отходы передает компании-закупщику и получает за это деньги.",
  answer_3              : "В приложении TAPON.AM абонент - это пользователь, который собирает бумажные отходы  отдельно от мусора и передает их агенту, в последствии чего получает за них баллы TapPoint.",
  answer_4              : "В приложении TAPON.AM один и тот же пользователь не может быть одновременно и агентом, и абонентом.",
  answer_5              : "TapPoint – это баллы, выдаваемые агентом за каждый килограмм бумажных отходов, предоставленный абонентом. Для получения бумажных отходов, агент получает или пополняет свои баллы TapPoint через приложение в предусмотренном порядке.",
  answer_6              : "Благодаря накопленным всеми абонентами баллам TapPoint в приложении, осуществляется реализация различных общеполезных программ.",
  answer_7              : "TAPON.AM предоставляет абонентам список общеполезных программ. Абоненты, путем голосования, выбирают только одну социальную программу, которая должна поддерживаться количеством накопленных баллов TapPoint. После реализации указанного проекта набранные всеми абонентами баллы обнуляются.",
  answer_8              : "Абонент запросить обратно свои баллы или изменить свое решение после реализации проекта и обнуления баллов не может.",
  answer_9              : "Обналичивание баллов TapPoint, полученных агентом или абонентом через приложение TAPON.AM или через любое другое приложение, невозможно.",
  answer_10             : "Баллы TapPoint предоставляются только приложением TAPON.AM. Получение баллов TapPoint другим способом невозможно.",
  answer_11             : "Баллы TapPoint не являются денежным средством или платежной системой. Осуществлять покупки за баллы TapPoint невозможно.",
  answer_12             : "Агент доставляет собранную им макулатуру компании-закупщику либо за свой счет, либо после сбора соответствующего количества обращается к компании-закупщику с целью организации перевозки.",
  answer_13             : "Приложение TAPON.AM не предоставляет транспортное средство для агента, так как это платформа для сборщиков бумажных отходов, сборщиков / переработчиков.",
  answer_14             : "Собирать бумажные отходы самостоятельно, а также рассказывать о приложении TAPON.AM окружающим (соседям, жильцам дома, коллегам). Советовать им также накапливать бумажные отходы и пользоваться приложением TAPON.AM, так как чем больше запросов о сдаче бумажных отходов будет открыто из одного и того же местоположения, тем больше будет вероятность того, что агент будет чаще посещать данное местоположение.",
  tapon_connects        : "ТAPON.AM – это мобильное приложение, которое поможет вам легко отправить накопленные бумажные отходы на переработку.",
  tapon_connects_text   : "TAPON.AM – это приложение предназначено для всех. Его могут легко использовать те, кто сортирует бумажные отходы отдельно от мусора в домашних условиях, и те, кто их собирает и отправляет на переработку. Проявляя активность, каждый пользователь участвует в программах по сокращению свалок, защите окружающей среды и различных общеполезных программах. Многие пользователи приложения уже отправляют бумажные отходы на переработку.",
  join_our_community    : "Присоединяйтесь к нам и узнайте, сколько деревьев вы спасли",
  projects              : "Проекты",
  about_app             : "О приложении",
  about_app_text        : "Будучи активным пользователем приложения TAPON.AM, каждый гражданин сможет принять участие и посодействовать процессу сортировки бумажных отходов и цивилизованного сокращения свалок, а также принять участие в реализации общеполезных программ, предложенных приложением TAPON.AM.",

  about_app_title1      : "Для чего разработано приложение",
  about_app_title2      : "Как оно работает",
  about_app_title3      : "Как стимулировать процесс сбора бумажных отходов",

  about_app_text1       : "Приложение TAPON.AM разработано для упрощения и эффективности процесса сбора и переработки бумажных отходов. Все пользователи нашего приложения, без лишних усилий, принимают участие в решении важнейших проблем по защите и сохранению чистоты окружающей среды.",
  about_app_text2       : "Участниками приложения являются абоненты (люди и организации, сортирующие бумажные отходы от бытового мусора и сдающие их) и агенты (люди, собирающие бумажные отходы от абонентов и сдающие их компании-закупщику). Абоненты, указывая количество  накопленных ими бумажных отходов и адресов, создают заявки. Агенты в режиме онлайн видят все заявки, созданные абонентами. Составляя маршрут, они поочередно собирают накопленные абонентами бумажные отходы.",
  about_app_text3       : "При составлении маршрутов агенты обычно отдают предпочтение адресам с наибольшим количеством абонентов и собранными ими бумажными отходами. Если вы не хотите, чтобы бумажные отходы, скопившиеся дома или на работе, ожидали прибытия агента в течение длительного времени, не забудьте рассказать о приложении Тapon.am соседям и друзьям. Количество сдаваемой вами бумажных отходов очень важно, так как  приложение вычисляет и позволяет узнать, сколько пользы Вы принесли окружающей среде.",
  about_app_box1_title  : "Дома, в офисе или в другом месте постоянно набираются бумажные отходы.",
  about_app_box2_title  : "Вы не работаете и/или желаете получать стабильный доход.",
  about_app_box1_desc1  : "Загрузи приложение и зарегистрируйся в качестве абонента (сдающего бумажные отходы), информируй о собранной макулатуре и сдавай ее без лишних усилий.",
  about_app_box1_desc2  : "Каждый абонент получает баллы Tappoint за выданные бумажные отходы. Баллы всех абонентов вместе направляются в качестве финансовых средств с целью реализации",
  about_app_box1_desc3  : "общеполезных программ.",
  about_app_box2_desc   : "Загрузи приложение и зарегистрируйся в качестве агента (сборщика бумажных отходов), получай информацию об адресах абонентов и количествах собранных бумажных отходов, по очереди посещай эти адреса, собирай бумажные отходы, сотрудничай с приложением TAPON.AM и продавай бумажные отходы   организации, гарантированной приложением. Для более детальной информации пиши нам",
  about_app_box2_desc2  : "сюда.",
  about_us              : "О нас",
  about_us_text         : "Знаете ли вы, что каждый гражданин Республики Армении ежегодно, по средним подсчетам, выбрасывает в мусорное ведро примерно 20 кг использованной бумаги и картона? Учитывая количество населения Республики Армении, ежегодно примерно 58,000,000 кг бумаги оказываются на свалках или загрязняют населенные пункты, а тaк же окружающую среду. Такое количество бумаги, по приблизительным подсчетам, занимает территорию площадью 500,000 кв. м. Для более четкого представления отметим, что эта территория равна целой территории города Дастакерта Сюникского региона. Помимо того, что бумага занимает место на свалке, она также загрязняет окружающую среду и наносит вред экологии.",
  about_us_text_2       : "Сегодня у нас есть хорошая возможность для цивилизованного и разумного обращения с бумажными отходами. Вместо того, чтобы превращать их в мусор, из бумажных отходов можно получить полезные, экологически чистые и потребляемые в быту продукты.",
  our_donations         : "Oбщеполезные программы",
  donation              : "Общеполезные программы",
  our_donations_hometext: "Через приложение TAPON.AM каждый пользователь сможет внести свой небольшой вклад в жизнь тех людей.",
  our_donations_text    : "Приложение TAPON.AM предлагает своим пользователям сортировать бумажные отходы от бытовых и сдавать их на переработку. За каждый сданный килограмм бумажных отходов приложение начисляет абоненту баллы, так называемые баллы TapPoints. TAPON.AM будет регулярно публиковать общеполезные программы, направленные на поддержку решения экологических проблем. Сдавая бумажные отходы на переработку, абоненты получают возможность проголосовать за одну из общеполезных программ, опубликованных приложением. Общеполезная программа, получившая наибольшее количество голосов в результате голосования, будет реализована благодаря общему количеству баллов, накопленных всеми остальными подписчиками. Каждый подписчик всегда может ознакомиться как с процессом голосования, так и с  результатами и накопленными баллами.",
  our_donations_text2   : "Выбор за Вами: выбросить ненужную бумагу в мусорный ящик, или, накопив ее, отправить  на переработку, принося пользу не только природе, но и окружающей среде.",
  donations_history     : "История общеполезных програм",
  view_participants     : "посмотреть участников",
  view_all              : "Посмотреть все",
  participants_list     : "Список участников",
  fund                  : "Фонд",
  participants          : "Участники",
  amount                : "Количество",
  date                  : "Дата",
  have_a_question       : "Возникли вопросы?",
  have_a_question_text  : "Если у Вас появились вопросы или предложения, обращайтесь к нам, и мы свяжемся с вами как можно скорее!",
  contact_form          : "ФОРМА ОБРАТНОЙ СВЯЗИ",
  terms_and_conditions  : "Условия и положения",
  privacy_policy        : "Политика конфиденциальности",
  leaderboard           : "Список лидеров",
  leaderboard_text      : <>В данном разделе представлены те граждане и компании, которые приняли самое активное участие в сборе бумажных отходов.<br/> Каждый из этих граждан и компаний внесли свой личный вклад в процесс по решению экологических проблем и реализацию общеполезных программ.<br/> Все граждане и компании,  активно участвующие в процессе сбора бумажных отходов, получат различные статусы :</>,
  individual            : "Частные лица",
  corporate             : "Корпоративный",
  name                  : "Имя",
  status                : "статус",
  cardboard_amount      : "Количество бумажных отходов",
  cardboard_weight      : "Вес бумажных отходов (Кг)",
  weight                : "Вес (Кг)",
  donated_amount        : "Баллы пожертвований",
  trees_saved           : "Ко-во спасенных деревьев",
  trees_saved_mobile    : "Спасенные деревья",
  point                 : "Баллы",
  contact_us_text       : "Отправьте нам свои вопросы или предложения, и мы свяжемся с вами как можно скорее",
  email                 : "Почта",
  phone_number          : "НОМЕР ТЕЛЕФОНА",
  message               : "Сообщение",
  send                  : "Отправить",
  close                 : "Закрыть",
  message_sent          : "Ваше сообщение было успешно отправлено! ",
  Thank_you_for         : "Благодарим Вас за обращение к нам. Мы свяжемся с вами как можно скорее",
  min_5_max_500         : "(минимум 5, максимум 500 символов)",
  list_is_empty         : "Список пуст",
  no_search             : "Нет результатов поиска",
  Search                : "Поиск",
  Eco_citizen           : "Эко гражданин",
  Eco_master            : "Эко мастер",
  Eco_genius            : "Эко гений",
  Eco_hero              : "Эко герой",
  Donation_empty_title  : <>В настоящее время<br/> oсуществленных проектов нет.</>,
  Donation_empty_descr  : <>Оставайтесь на связи с нами ,<br/> чтобы быть в курсе обновленной информации. <br/> Спасибо!</>,
  Map_address1          : "Ул. Гранта Варданяна 8/2, Ереван",
  Map_address2          : "Гранта Варданяна 4/5, Масис",
  Address               : "АДРЕС",

  errorMessages: {
    name_error          : "Ваше имя должно содержать не менее 4 символов",
    email_error         : "Адрес эл. почты должен содержать не менее 5 символов и символ  @",
    phone_number_error  : "Пожалуйста, напишите свой номер телефона (+374XXXXXXXX)",
    message_error       : "Пожалуйста, напишите ваше сообщение (минимум 5, максимум 500 символов)",
  },

  help_text1            : "Соберите бумажные отходы отдельно от мусора.",
  help_text2            : "Поместите собранные бумажные отходы в картонную коробку, в бумажный или полиэтиленовый пакет, сжав и уплотнив их.",
  help_text3            : "Взвесьте собранные  бумажные отходы в сжатом виде, затем укажите вес на коробке или пакете.",
  help_text4            : "Зарегистрируйте накопленный  на данный момент  вес бумажных отходов в приложении TAPON.AM. Выберите  в приложении предпочтительное   время в течение дня для сдачи бумажных отходов.",
  help_text5            : "У Вас скопилось дополнительное количество бумажных отходов перед сдачей? Не нужно создавать новый запрос. Откройте ранее созданный Вами запрос, замените предыдущий вес новым, указав суммарное количество предыдущего и нового веса бумажных отходов.",
  help_text6            : "Следуйте сообщениям агента по утилизации бумаги. В указанное время в указанном месте сдайте бумажные отходы , получите баллы и накапливайте их.",
  help_text7            : "Участвуйте в голосовании через приложение, выберите одну из предложенных общеполезных программ. Победившая программа будет реализована с помощью баллов, накопленных Вами и всеми остальными участниками.",
};