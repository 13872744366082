import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { ROUTES } from '../../../platform/constants/routes';
import Languages from '../../../platform/services/languages'
import Logo from '../../../assets/images/Logo_new.svg'
import ClickOutSide from '../../../platform/click-outside/ClickOutside'

const {
  home,
  contact_us,
  about_app,
  leaderboard,
  FAQ,
  projects,
} = Languages.Translations;
class MobileHeader extends React.Component {

  state = {
    whiteHeader       : true,
    scroll            : false,
    headerDonations   : false,
    headerHome        : false,
    headerContact     : false,
    headerLeaderboard : false,
    isOpen            : false,
    headerAbout       : false,
    headerFAQ         : false,
    page              : 0,
    size              : 5,
  }

  listenScrollEvent = e => {
    if (window.scrollY > 100) {
      this.setState({scroll: true})
    } else {
      this.setState({scroll: false})
    }
  }

  componentDidMount() {
    this.checkHeader(window.location.pathname)
    window.addEventListener('scroll', this.listenScrollEvent)

    window.routerHistory.listen((data) => {
      this.checkHeader(data.pathname);
    });
  }

  checkHeader = (path) => {
    if (path ===  ROUTES.HOME && this.state.whiteHeader) {
      this.setState({ whiteHeader: false , headerHome: true });
    } else this.setState({  whiteHeader: true , headerHome: false })

    if (path === ROUTES.DONATIONS) {
      this.setState({ headerDonations: true})
    } else this.setState({ headerDonations: false})

    if (path === ROUTES.CONTACT_US) {
      this.setState({ headerContact: true})
    } else this.setState({ headerContact: false})

    if (path === ROUTES.LEADERBOARD) {
      this.setState({ headerLeaderboard: true})
    } else this.setState({ headerLeaderboard: false})

    if (path === ROUTES.ABOUT_APP) {
      this.setState({ headerAbout: true})
    } else this.setState({ headerAbout: false})

    if (path === ROUTES.FAQ) {
      this.setState({ headerFAQ: true})
    } else this.setState({ headerFAQ: false})
  }

  openMenu = () => {
    this.setState({ isOpen: true})
    document.body.style.overflow = "hidden";
  }

  closeMenu = () => {
    this.setState({ isOpen: false})
    document.body.style.overflow = "auto";
  }

  // getWinnProjects = async () => {
  //   const { page, size } = this.state
  //   const result = await DonationController.getWinnProjects({page, size});
  //   if (result.success) {
  //     this.setState({ 
  //       donationList: result.data.content,
  //     })
  //   }
  // }

  render () {
    return (

      <header className={`${!this.state.whiteHeader  ? "P-header-mobile" : "P-header-mobile"}`}>
            
        <ClickOutSide onClickOutside={this.closeMenu} className="G-width-100">
          <div className="P-menu-btn G-cursor G-flex G-align-center G-justify-between">
            <div onClick={this.openMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div  className="P-header-logo">
              <Link to="/">
                <img src={Logo} alt=""/>
              </Link>
            </div>
            <div></div>
          </div>
  
          <nav className={`${this.state.isOpen ? "P-is-open" : ""}`}>
            <Link  className="P-mobile-logo" to="/">
              <img src={Logo} alt=""/>
            </Link>
            <div className="icon-cancel" onClick={this.closeMenu}></div>

            <div className="P-header-link-mobile" onClick={this.closeMenu}>
              <Link className={`${this.state.headerHome ? "G-bold" : ""}`} to="/">{home}</Link>
              <Link className={`${this.state.headerAbout ? "G-bold" : ""}`} to={ROUTES.ABOUT_APP}>{about_app}</Link>
              <Link className={`${this.state.headerDonations ? "G-bold" : ""}`} to={ROUTES.DONATIONS}>{projects}</Link>
              <Link className={`${this.state.headerLeaderboard ? "G-bold" : ""}`} to={ROUTES.LEADERBOARD}>{leaderboard}</Link>
              <Link className={`${this.state.headerFAQ ? "G-bold" : ""}`} to={ROUTES.FAQ}>{FAQ}</Link>
              <Link className={`${this.state.headerContact ? "G-bold" : ""}`} to={ROUTES.CONTACT_US}>{contact_us}</Link>
            </div>
          </nav>
        </ClickOutSide>
      </header>

    )
  }
}

export default MobileHeader;