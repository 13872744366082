import React from 'react';

export default {
  language_label        : 'en',
  home                  : 'Home',
  english               : 'English',
  russian               : 'Russian',
  armenian              : 'Armenian',
  language              : 'Language',
  contact_us            : "Contact us",
  donations             : "Public benefit programs",
  FAQ                   : "Help",
  asked_questions       : "Frequently asked questions",
  asked_questions_top   : "How to collect paper waste?",
  asked_questions_desc  : "This FAQ provides answers to basic questions about TAPON.AM",
  question_1            : "1. Why should I separate paper waste from rubbish, collect it and handle?",
  question_2            : "2. Who is the agent?",
  question_3            : "3. Who is the subscriber?",
  question_4            : "4. Can one user be an agent and a subscriber simultaneously in TAPON.AM application?",
  question_5            : "5. What is a TapPoint?",
  question_6            : "6. What is the target of collecting TapPoint points by subscribers?",
  question_7            : "7. In what way public benefit programs are selected and how are they realized?",
  question_8            : "8. Can the subscriber recall his/her points or change his decision after the benefit project is realized and the points are reset?", 
  question_9            : "9. Can TapPoint points be converted to Armenian drams?",
  question_10           : "10.  Can Armenian drams be converted to TapPoint points?", 
  question_11           : "11. Can one do shopping by using TapPoint points?", 
  question_12           : "12. How can the agent pass his/her collected paper waste to procuring company?", 
  question_13           : "13.  Does TAPON.AM application provide a vehicle for the collection of paper waste?", 
  question_14           : "14. What shall be done so that the agent frequently visits your house, apartment or any other point of paper waste handling?",
  answer_1              : "This way act members of civilized societies, besides this way act also those people who think about the environment and ecology.",
  answer_2              : "In TAPON.AM application the agent is the user who collects paper waste from subscribers, grants them TapPoint points, passes the collected waste paper to procuring company and gets money for this.",
  answer_3              : "In TAPON.AM application the subscriber is the user who collects paper waste separately from rubbish, passes it to the agent and afterwards gets TapPoint points for this. ",
  answer_4              : "In TAPON.AM application, the same user cannot be both an agent and a subscriber.",
  answer_5              : "TapPoint is a point granted by the agent for each kilogram of paper waste provided by the subscriber. For getting paper waste the agent receives or refills his/her TapPoint points through the application in a designated way.",
  answer_6              : "Due to TapPoint points collected by all the subscribers in the application, different public benefit programms are realized.",
  answer_7              : "TAPON.AM provides the subscribers with the list of public benefit programms. By voting the subscribers choose only one public benefit programms which shall be supported by the quantity of collected TapPoint points. After realization of the actual project all the points collected by subscribers are reset.   ",
  answer_8              : "The subscriber can’t request his points back or change his decision after the project is completed and the points are reset.",
  answer_9              : "Cashing of TapPoint points received by the agent or subscriber through TAPON.AM application is impossible. ",
  answer_10             : "TapPoint points are granted solely by TAPON.AM application. Getting TapPoint points by other means is impossible.",
  answer_11             : "TapPoint points are neither a payment method, nor a payment system. Doing shopping with TapPoint points is impossible.",
  answer_12             : "The agent delivers his/her collected paper waste to procuring company either on his/her own expenses or after collecting a relevant quantity of waste turns to procuring company with the aim of organizing the transportation.",
  answer_13             : "TAPON.AM doesn’t provide a vehicle for the agent, as it is a platform for waste collectors, handlers and recyclers.",
  answer_14             : "Collect paper waste on your own, as well as tell your entourage about TAPON.AM application (neighbors, house residents, colleagues). Advise them to also collect paper waste and use TAPON.AM application because the more requests about paper waste handling are received from the same location, the more frequently the agent will visit that location.",
  tapon_connects        : "TAPON.AM is a mobile application which helps you to easily send your accumulated waste paper for recycling.",
  tapon_connects_text   : "TAPON.AM mobile app is intended for everyone. It can be easily used by those who separate paper waste from rubbish at home, as well as those who collect and hand it over for recycling. Being active, each user participates in benefit and various programs to reduce landfills, protect the environment etc. Many users of the app have already sent paper waste out for recycling.",
  join_our_community    : "Join us and find out how many trees you have saved.",
  projects              : "Projects",
  about_app             : "About app",
  about_app_text        : "By actively using TAPON.AM application each citizen can participate and contribute to waste sorting and civilized process of decreasing the amount of waste dumps as well as take part in public benefit programs offered by TAPON.AM application.",
  about_app_title1      : "What is the application for",
  about_app_title2      : "How does it work",
  about_app_title3      : "How to stimulate the waste paper collection process",

  about_app_text1       : "TAPON.AM application is designed for the efficiency and simplification of waste paper  collecting and recycling process. All users of our application take part in solving the most important problems of protecting and maintaining clean environment without any hassle.",
  about_app_text2       : "Application participants are subscribers (people and organizations that sort paper waste from household waste and hand it over) and agents (people who collect paper waste from subscribers and pass it to recycling company). Subscribers, indicating the amount of accumulated paper waste and addresses, create requests. Agents see all requests created by subscribers online. They create the route and take turns collecting paper waste accumulated by subscribers for recycling.",
  about_app_text3       : "Agents usually give preference to those addresses where the number of subscribers and amount of paper waste collected by them is the largest.  If you don’t want to keep the paper waste accumulated at home or at work for a long time waiting for the agent to collect it, don’t forget to tell your neighbors and friends about TAPON.AM application. The amount of waste paper you hand over is very important as the app calculates and lets you know how much you have done for the environment.",

  about_app_box1_title  : "Paper waste is permanently accumulated at home, in the office or some other place.",
  about_app_box2_title  : "You have no job and/or you wish to have stable income.",
  about_app_box1_desc1  : "Download the application and register as a subscriber (waste paper handler), inform about the waste collected and handle it without any extra efforts.",
  about_app_box1_desc2  : "Each subscriber gets points against the waste handled. The points earned by the subscribers altogether are directed as financial means to different social",
  about_app_box1_desc3  : "benefit programs.",
  about_app_box2_desc   : "Download the application and register as a subscriber (waster paper collector), be informed about the addresses of subscribers and collected waste paper quantities, visit the users’ addresses in turn, collect the waste paper, cooperate with TAPON.AM app and sell it to the organization guaranteed by the application. For more detailed information, write to us",
  about_app_box2_desc2  : "here.",
  about_us              : "About us",
  about_us_text         : "Do you know that each citizen of the Republic of Armenia throws 20 kg of used paper and cardboard away as a rubbish according to average annual calculations? Considering the population of the Republic of Armenia, around 58,000,000 kg of paper occur in waste dumps annually or pollute local communities as well as the environment in general. Such quantity of paper by average calculations occupies a territory of 500,000 square meters. For better enhancement we may point out that it is equal to the overall territory of Dastakert city in Syunik region. Besides occupying space in waste dumps it also pollutes the environment and inflicts the ecology.",
  about_us_text_2       : "Today we have a good opportunity of civilized and rational treatment of paper waste. Instead of turning it into a rubbish, the paper waste can serve as means of getting useful, ecologically pure and day-to-day demanded products.",
  our_donations         : "Public benefit programs",
  donation              : "Public benefit programs",
  our_donations_hometext: "Through TAPON.AM application each user can make a small contribution in extending support to someone.",
  our_donations_text    : "TAPON.AM application offers its users to separate paper from household waste and hand it over for recycling. For each kilogram of handed over paper waste, subscribers receive points called TapPoints. TAPON.AM will regularly publish public benefit programs aimed at supporting the solutions of environmental problems. By handing over paper waste for recycling, subscribers get the opportunity to vote for one of the public benefit programs published by the application. The public benefit program that gets the most votes from the voting will become a reality thanks to the total points accumulated by all other subscribers. Each subscriber can always follow the voting process, the results and accumulated points.",
  our_donations_text2   : "The choice is yours - throw unnecessary paper in the bin or accumulate it and provide it for recycling helping not only nature but also environment.",
  donations_history     : "Public benefit programs history",
  view_participants     : "view paticipants",
  view_all              : "View all ",
  participants_list     : "Participants list",
  fund                  : "Fund",
  participants          : "Participants",
  amount                : "Amount",
  date                  : "Date",
  have_a_question       : "Any questions?",
  have_a_question_text  : "We will get back to you as soon as possible",
  contact_form          : "CONTACT FORM",
  terms_and_conditions  : "Terms and Conditions",
  privacy_policy        : "Privacy Policy",
  leaderboard           : "Leaderboard",
  leaderboard_text      : <>Here you may find the list of those citizens and companies who has the most active participation in the waste paper collection process. <br/>Each of these citizens and companies had their personal contribution to environmental solutions and realization of public benefit programs.<br/> All citizens and companies actively involved in  paper waste collection process will receive various statuses: </>,
  individual            : "Individuals",
  corporate             : "Corporate",
  name                  : "NAME",
  status                : "STATUS",
  cardboard_amount      : "CARDBOARD AMOUNT",
  cardboard_weight      : "CARDBOARD WEIGHT (KG)",
  weight                : "WEIGHT (KG)",
  donated_amount        : "DONATED POINTS",
  trees_saved           : "Number of trees saved",
  trees_saved_mobile    : "Saved trees",
  point                 : "POINTS",
  contact_us_text       : "Send us your questions or suggestions and we will contact you as soon as possible",
  email                 : "EMAIL",
  phone_number          : "PHONE NUMBER",
  message               : "MESSAGE",
  send                  : "Send",
  close                 : "Close",
  message_sent          : "Your message was successfully sent!",
  Thank_you_for         : "Thank you for contacting us. We will contact you as soon as possible",
  min_5_max_500         : "(min 5, max 500 symbols)",
  list_is_empty         : "List is empty",
  no_search             : "No search result",
  Search                : "Search",
  Eco_citizen           : "Eco citizen",
  Eco_master            : "Eco master",
  Eco_genius            : "Eco genius",
  Eco_hero              : "Eco hero",
  Donation_empty_title  : <>There are no<br/> implemented projects yet.</>,
  Donation_empty_descr  : <>Stay in touch with us for updated information.<br/> Thanks!</>,
  Map_address1          : "8/2 Hrant Vardanyan Str, Yerevan",
  Map_address2          : "4/5 Hrant Vardanyan Str, Masis",
  Address               : "ADDRESS",
  
  errorMessages: {
    name_error          : "Your name should contain at least 4 simbols",
    email_error         : "Email should contain at least 5 simbols and contain @ simbol",
    phone_number_error  : "Please write your phone number (+374XXXXXXXX)",
    message_error       : "Please write your message (min 5, max 500 symbols)",
  },

  help_text1            : "Collect paper waste separately from rubbish.",
  help_text2            : "Place the collected paper waste in a cardboard box, in a paper or plastic bag, compressing and compacting them.",
  help_text3            : "Weigh the collected paper waste in a compressed form and then mark the weight on the box or bag.",
  help_text4            : "Register your current paper waste weight in TAPON.AM application. Choose your preferred time during the day and handle it.",
  help_text5            : "Open the request you created earlier, replace the previous weight with the new one, indicating the total amount of previous and new weights of paper waste.",
  help_text6            : "Follow the messages from the paper waste agent. At mentioned time hand over the paper waste in the mentioned place, earn and accumulate points.",
  help_text7            : "Participate in voting through the application, choose one of the proposed public benefit programs. The winning program will be implemented with the points accumulated by you and all the other participants.",
};