import Connection from '../../services/connection';

class DonationController {

  static getWinnProjects = async (form) => {
    return Connection.GET({
      controller:'donationProject',
      action: `getAllWinnProjects/${form.page}/${form.size}`,
    });
  }

  static getParticipantsList = async (campaignId, text) => {
    return Connection.GET({
      controller:'donationProject',
      action: `getAllDonatedParticipants?campaignId=${campaignId}&text=${text}`,
    });
  }
}

export default DonationController;