import Connection from '../../services/connection';

class FAQController {

  static getAllFAQs = async () => {
    return Connection.POST({
      controller:'faq',
      action: `getAllFAQsForWeb`,
      body: {searchText: ''}
    });
  }
}

export default FAQController;