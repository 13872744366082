import Connection from '../../services/connection';

class LeaderBoardController {

  static getCorporatesList = async () => {
    return Connection.GET({
      controller:'leaderBoard',
      action: `getTopTenCorporates`,
    });
  }

  static getIndividualsList = async () => {
    return Connection.GET({
      controller: 'leaderBoard',
      action: `getTopTenIndividuals`,
    });
  }
}

export default LeaderBoardController;
