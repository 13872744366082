import React from 'react';

class Mobile extends React.Component {

state = {
  isMobile: false,
}


changeMobile = () => {
  if (window.innerWidth < 560) {
    this.setState({ isMobile: true})
   } else this.setState({ isMobile: false})
}

componentDidMount() {
  window.addEventListener('resize', (event) => {
    this.changeMobile();
  });
  this.changeMobile();
}
render() {
  return(
    this.state.isMobile ? this.props.children : null
  )
}
}

export default Mobile;