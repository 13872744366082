import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export default class AppearAnimation extends PureComponent {

  static propTypes = {
    tag       : PropTypes.string,
    children  : PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
    duration  : PropTypes.number,
    className : PropTypes.string,
  }

  static defaultProps = {
    tag       : 'h6',
    duration  : 400,
    className : '',
  }

  state = {
    isMobile : false,
    showText : false,
  }

  componentDidMount() {
    this.onScroll();
    this.onResize();
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    if (this.isInViewport(this.textElem) && !this.state.showText){
      const { duration } = this.props;
      if (duration) {
        setTimeout(() => this.setState({ showText: true }), duration);
      } else {
        this.setState({ showText: true });
      }
    }
  }
  onResize = () => this.setState({ isMobile: window.innerWidth <= 992 });

  isInViewport = (el) => {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    let width = el.offsetWidth;
    let height = el.offsetHeight;

  
    while(el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop + 100;
      left += el.offsetLeft;
    }
  
    return (
      top < (window.pageYOffset + window.innerHeight) &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
    );
  }

  render() {
    const { showText } = this.state;
    const { tag, children, className } = this.props;
    const TagName = tag;
    return (
      <TagName ref={elem => this.textElem = elem} {...this.props} className={showText ? `${className ? `${className} ` : ''}AB-appear-animation AB-appear-animation-show` : `${className ? `${className} ` : ''}AB-appear-animation`}>
        {children}
      </TagName>
    );
  }
}
